import { useCallback, useState } from 'react';
import { Theme } from '@raydiant/api-client-js';
import { useDispatch, useSelector } from 'react-redux';
import Popover from '../../components/Popover';
import PopoverListItem from '../../components/Popover/PopoverListItem';
import * as themeActions from '../../actions/themes';
import {
  selectUserProfile,
  selectIsEnterpriseUser,
} from '../../selectors/user';
import {
  canEditResource,
  canShareResource,
  canDeleteResource,
  getDuplicateResourceName,
} from '../../utilities';
import useDuplicateSnackbar from '../../hooks/useDuplicateSnackbar';
import DeleteThemeConfirmation from './DeleteThemeConfirmation';
import useCustomThemes from './useCustomThemes';

export interface ThemeManagerItemActionsProps {
  anchorEl: HTMLButtonElement | null;
  theme: Theme;
  onClose: () => void;
  onShare: () => void;
  onEdit: () => void;
  onDirty: (isDirty: boolean) => void;
}

const ThemeManagerItemActions = ({
  anchorEl,
  theme,
  onClose,
  onShare,
  onEdit,
  onDirty,
}: ThemeManagerItemActionsProps) => {
  const dispatch = useDispatch();
  const customThemes = useCustomThemes();
  const { enqueueDuplicateSnackbar } = useDuplicateSnackbar();

  // State

  const [isDeletePromptOpen, setIsDeletePromptOpen] = useState(false);

  // Selectors

  const currentUser = useSelector(selectUserProfile);
  const isEnterpriseUser = useSelector(selectIsEnterpriseUser);

  // Callbacks

  const editTheme = useCallback(() => {
    onClose();
    onEdit();
  }, [onClose, onEdit]);

  const duplicateTheme = useCallback(() => {
    dispatch(
      themeActions.createTheme(
        {
          ...theme,
          name: getDuplicateResourceName(theme.name, customThemes),
        },
        {
          onCreate: () => enqueueDuplicateSnackbar(false),
        },
      ),
    );
    onClose();
    onDirty(true);
  }, [
    theme,
    onClose,
    dispatch,
    onDirty,
    customThemes,
    enqueueDuplicateSnackbar,
  ]);

  const shareTheme = useCallback(() => {
    onClose();
    onShare();
    onDirty(true);
  }, [onClose, onShare, onDirty]);

  const deleteTheme = useCallback(() => {
    dispatch(themeActions.deleteAllThemes([theme.id], {}));
    onClose();
    onDirty(true);
  }, [theme, onClose, dispatch, onDirty]);

  const handleClose = useCallback(() => {
    setIsDeletePromptOpen(false);
    onClose();
  }, [onClose]);

  // Render

  const canEdit = currentUser && canEditResource(currentUser, theme.resource);

  const canShare =
    currentUser &&
    isEnterpriseUser &&
    theme.presentationId === null && // Can't share one-off themes.
    canShareResource(currentUser, theme.resource);

  const canDelete =
    currentUser && canDeleteResource(currentUser, theme.resource);

  return (
    <>
      <Popover
        variant="list"
        open={!!anchorEl && !isDeletePromptOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {canEdit && (
          <PopoverListItem onClick={editTheme} disabled={!canEdit}>
            Edit
          </PopoverListItem>
        )}

        <PopoverListItem onClick={duplicateTheme}>Duplicate</PopoverListItem>

        {isEnterpriseUser && (
          <PopoverListItem onClick={shareTheme} disabled={!canShare}>
            Share
          </PopoverListItem>
        )}

        <PopoverListItem
          onClick={() => setIsDeletePromptOpen(true)}
          disabled={!canDelete}
        >
          Delete
        </PopoverListItem>
      </Popover>

      <DeleteThemeConfirmation
        open={!!anchorEl && isDeletePromptOpen}
        anchorEl={anchorEl}
        themes={[theme]}
        onClose={handleClose}
        onDelete={deleteTheme}
      />
    </>
  );
};

export default ThemeManagerItemActions;
