import { FC, useState, useEffect, useMemo } from 'react';
import { Playlist } from '@raydiant/api-client-js';
import { areTagsEqual, hasValidTags } from '../../utilities/tagUtils';
import TagManager from '../../components/TagManager';
import usePlaylistPageContext from './usePlaylistPageContext';
import PlaylistModalBody from './PlaylistModalBody';
import PlaylistModalFooter from './PlaylistModalFooter';

interface PlaylistModalTagsProps {
  playlist: Playlist;
  onClose: () => void;
}

const PlaylistModalTags: FC<PlaylistModalTagsProps> = ({
  playlist,
  onClose,
}) => {
  const { updatePlaylist } = usePlaylistPageContext();

  // State

  const [tags, setTags] = useState(playlist.resource.r.tags);

  // Memoizers

  const isDirty = useMemo(() => {
    return !areTagsEqual(tags, playlist.resource.r.tags);
  }, [tags, playlist]);

  // Callbacks

  const handleDone = () => {
    const params: DeepPartial<Playlist> = {
      resource: { r: { tags } },
    };

    updatePlaylist(playlist.id, params);

    onClose();
  };

  // Side-effects

  // Update state when playlist changes.
  useEffect(() => {
    setTags(playlist.resource.r.tags);
  }, [playlist]);

  // Render

  const enableDoneButton = isDirty && hasValidTags(tags);

  return (
    <>
      <PlaylistModalBody>
        <TagManager tags={tags} onChange={setTags} />
      </PlaylistModalBody>

      <PlaylistModalFooter
        enableDone={enableDoneButton}
        onCancel={onClose}
        onDone={handleDone}
      />
    </>
  );
};

export default PlaylistModalTags;
