import { createSelector } from 'reselect';
import * as P from '../../clients/mira/types/Presentation';
import logger from '../../logger';
import { RootState } from '../../reducers';
import { selectUserProfile } from '../../selectors/user';
import { selectApplications } from '../../selectors/applications';
import { selectThemesById } from '../../selectors/v2/themes';
import { selectQueryParam, selectRouteParam } from '../../selectors/routing';

export default createSelector(
  [
    selectApplications,
    selectUserProfile,
    selectThemesById,
    selectRouteParam('applicationId'),
    selectQueryParam('applicationVariables'),
    (state: RootState) => state.applicationPage.isLoading,
  ],
  (
    applications,
    currentUser,
    themesById,
    applicationId,
    applicationVariablesString,
    isLoading,
  ) => {
    let applicationVariables: P.ApplicationVariables | undefined;
    if (applicationVariablesString) {
      try {
        applicationVariables = JSON.parse(applicationVariablesString);
      } catch (err: any) {
        logger.error(
          new Error(`Error parsing applicationVariables: ${err.message}`),
        );
      }
    }

    return {
      application: applications.find((app) => app.id === applicationId),
      applicationId,
      themesById,
      currentUser,
      applicationVariables,
      isLoading,
    };
  },
);
