import { FC, useMemo, useState, useRef } from 'react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import HttpsIcon from '@material-ui/icons/Https';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from 'raydiant-elements/core/Checkbox';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import AlertIcon from 'raydiant-elements/core/AlertIcon';
import Button from 'raydiant-elements/core/Button';
import Row from 'raydiant-elements/layout/Row';
import isTouchDevice from 'is-touch-device';
import { Resource } from '@raydiant/api-client-js';
import { selectUserProfile } from '../../selectors/user';
import { canDeleteResource } from '../../utilities';
import useStyles from './LibraryResourceCard.styles';
import LibraryResourceCardActions from './LibraryResourceCardActions';

interface LibraryResourceCardProps {
  name: React.ReactNode;
  icon?: React.ReactNode;
  resource: Resource;
  mode?: 'select' | 'edit' | 'manage';
  isSelected?: boolean;
  isLoading?: boolean;
  isLocked?: boolean;
  hasError?: boolean;
  showSelect?: boolean;
  hideCopy?: boolean;
  onSelect: () => void;
  onEdit?: () => void;
  onOverlayClick?: () => void;
  onCopy?: () => void;
  onDelete?: () => void;
  children: (props: RenderProps) => React.ReactNode;
}

interface RenderProps {
  isDark: boolean;
}

const LibraryResourceCard: FC<LibraryResourceCardProps> = (
  {
    name,
    icon,
    resource,
    mode = 'edit',
    isSelected = false,
    isLoading = false,
    isLocked = false,
    hasError = false,
    showSelect,
    hideCopy,
    onSelect,
    onEdit,
    onCopy,
    onDelete,
    onOverlayClick,
    children,
  },
  ref,
) => {
  const classes = useStyles();

  // Selectors

  const currentUser = useSelector(selectUserProfile);

  // State

  const [isActive, setIsActive] = useState(false);
  const [isActionsOpen, setIsActionsOpen] = useState<boolean>(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState<boolean>(false);

  // Refs

  const moreActionsRef = useRef<HTMLButtonElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  // Memoizers

  const handlers = useMemo(() => {
    if (isTouchDevice()) {
      return {
        onFocus: () => setIsActive(true),
        onBlur: () => setIsActive(false),
        onClick: () => setIsActive(true),
      };
    }

    return {
      onFocus: () => setIsActive(true),
      onBlur: (e: React.FocusEvent) => {
        // To fix an issue in Chrome / Edge, if the target element is a descendant of
        // the container do not set isActive=false.
        if (!containerRef.current) return;
        if (containerRef.current.contains(e.target)) return;
        setIsActive(false);
      },
      onMouseEnter: () => {
        setIsActive(true);
      },
      onMouseLeave: () => {
        setIsActive(false);
      },
    };
  }, []);

  // Render

  const shouldShowEdit =
    !isDeleteConfirmationOpen &&
    mode !== 'manage' &&
    isActive &&
    !isLocked &&
    !!onEdit;

  const shouldShowPrimaryEdit = shouldShowEdit && mode === 'edit';
  const shouldShowSecondaryEdit = shouldShowEdit && mode !== 'edit';

  const shouldShowSelect =
    !isDeleteConfirmationOpen &&
    ((showSelect && !!onSelect) ||
      (mode === 'select' && isActive && !!onSelect) ||
      (mode === 'manage' && !!onSelect) ||
      isSelected);

  const shouldShowPrimarySelect = shouldShowSelect && mode === 'select';
  const shouldShowSecondarySelect = shouldShowSelect && mode !== 'select';

  const shouldShowError =
    !isDeleteConfirmationOpen && !shouldShowSelect && hasError;

  const shouldShowMoreActions =
    !isDeleteConfirmationOpen &&
    (isActive || isActionsOpen) &&
    (!!onDelete || !!onCopy);

  const shouldShowProgress =
    !isDeleteConfirmationOpen && isLoading && !hasError && !shouldShowSelect;

  const shouldShowLock = isLocked;

  const shouldDarkenOverlay =
    isDeleteConfirmationOpen ||
    shouldShowEdit ||
    shouldShowProgress ||
    (isActive && !!onOverlayClick);

  const canDelete = currentUser && canDeleteResource(currentUser, resource);

  const canDuplicate = !isLoading && !hasError;

  const handleOverlayClick = ['select', 'manage'].includes(mode)
    ? onSelect
    : onOverlayClick;
  // const handleOverlayClick = undefined;

  const isOverlayClickable = !!handleOverlayClick;

  return (
    <div ref={containerRef} className={classes.root} {...handlers}>
      {/* The ref should be applied on the thumbnail for correct drag and drop calculations */}
      <div className={classes.thumbnail} ref={ref}>
        {children({ isDark: shouldDarkenOverlay })}

        {isOverlayClickable ? (
          // role="button" because react-dnd does not register the drag event on buttons
          // in some browsers: https://github.com/react-dnd/react-dnd/issues/803
          <div
            role="button"
            className={classes.overlay}
            onClick={handleOverlayClick}
          />
        ) : (
          <div className={classes.overlay} />
        )}

        {shouldShowSecondarySelect && (
          <div className={classes.topLeft}>
            <Checkbox round checked={isSelected} onChange={onSelect} />
          </div>
        )}

        {shouldShowSecondaryEdit && (
          <div className={classes.topLeft}>
            <IconButton
              className={cn(
                classes.button,
                classes.buttonBorder,
                classes.buttonSecondary,
              )}
              onClick={onEdit}
            >
              <EditSharpIcon color="inherit" fontSize="inherit" />
            </IconButton>
          </div>
        )}

        {shouldShowError && (
          <div className={classes.topLeft}>
            <AlertIcon className={classes.errorIcon} />
          </div>
        )}

        {shouldShowProgress && (
          <div className={classes.topLeft}>
            <CircularProgress color="light" />
          </div>
        )}

        {shouldShowLock && (
          <div className={classes.lock}>
            <HttpsIcon fontSize="inherit" />
          </div>
        )}

        {shouldShowPrimarySelect && (
          <div className={classes.center}>
            <Checkbox
              round
              checked={isSelected}
              onChange={onSelect}
              className={classes.checkboxPrimary}
            />
          </div>
        )}

        {shouldShowPrimaryEdit && (
          <div className={classes.center}>
            <IconButton
              className={cn(
                classes.button,
                classes.buttonBorder,
                classes.buttonPrimary,
              )}
              onClick={onEdit}
            >
              <EditSharpIcon color="inherit" fontSize="inherit" />
            </IconButton>
          </div>
        )}

        {shouldShowMoreActions && (
          <>
            <IconButton
              edge="end"
              size="small"
              className={cn(classes.button, classes.moreActions)}
              ref={moreActionsRef}
              onClick={() => setIsActionsOpen(true)}
            >
              <MoreHorizIcon
                color="inherit"
                className={classes.moreActionsIcon}
              />
            </IconButton>

            <LibraryResourceCardActions
              anchorEl={moreActionsRef.current}
              open={isActionsOpen}
              hideCopy={hideCopy}
              onCopy={canDuplicate ? onCopy : undefined}
              onDelete={
                canDelete ? () => setIsDeleteConfirmationOpen(true) : undefined
              }
              onClose={() => {
                setIsActionsOpen(false);
                setIsActive(false);
              }}
            />
          </>
        )}

        {isDeleteConfirmationOpen && (
          <div className={classes.deleteConfirmation}>
            <Button
              fullWidth
              size="small"
              color="destructive"
              label="Delete"
              icon={<DeleteForeverIcon />}
              onClick={onDelete}
            />
            <Button
              fullWidth
              size="small"
              label="Cancel"
              onClick={() => setIsDeleteConfirmationOpen(false)}
            />
          </div>
        )}
      </div>
      <Row halfMargin center>
        {icon && <div className={classes.icon}>{icon}</div>}
        <div className={classes.name}>{name}</div>
      </Row>
    </div>
  );
};

// @ts-ignore
export default React.forwardRef(LibraryResourceCard);
