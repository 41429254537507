import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Presentation } from '@raydiant/api-client-js';
import PaperModal from 'raydiant-elements/core/PaperModal';
import * as paths from '../../routes/paths';
import { selectPlaylistsById } from '../../selectors/v2/playlists';
import { selectDomainForCurrentUser } from '../../selectors/v2/domains';
import { selectUserProfile } from '../../selectors/user';
import { canReadResource } from '../../utilities';
import { createNewId } from '../../utilities/identifiers';
import PlaylistSelector from '../../components/PlaylistSelector';
import ReadResourceWarning from '../../components/ReadResourceWarning';
import { Path } from '../../types';
import { useCallback } from 'react';
import getPageUrl from './getPageUrl';
import useStyles from './PresentationPage.styles';
import useQueryParams from './useQueryParams';

interface PlaylistSelectorModalProps {
  presentation?: Presentation;
  selectedPlaylistPath: Path;
  onClose: () => void;
  onSelect: (playlistId: string) => void;
}

const PlaylistSelectorModal = ({
  presentation,
  selectedPlaylistPath,
  onClose,
  onSelect,
}: PlaylistSelectorModalProps) => {
  const classes = useStyles();
  const history = useHistory();
  const queryParams = useQueryParams();

  // Selectors

  const playlistsById = useSelector(selectPlaylistsById);
  const domain = useSelector(selectDomainForCurrentUser);
  const currentUser = useSelector(selectUserProfile);

  // State

  const [isReadResourceWarningOpen, setIsReadResourceWarningOpen] =
    useState(false);
  const [selectedProfileId, setSelectedProfileId] = useState<string | null>(
    null,
  );

  // Refs

  const resolveReadResourceWarningRef = useRef<
    ((result: boolean) => void) | null
  >(null);

  // Callbacks

  const newPresentation = useCallback(
    (applicationId: string) => {
      if (!presentation) return;

      history.push(
        paths.newPresentation({
          ...queryParams,
          applicationId,
          backTo: getPageUrl({ presentation, queryParams }),
          backToLabel: `Back to ${presentation?.name}`,
          saveTo: getPageUrl({ presentation, queryParams }),
        }),
      );
    },
    [presentation, queryParams, history],
  );

  const newPlaylist = useCallback(() => {
    if (!presentation) return;

    history.push(
      paths.newPlaylist({
        playlistId: createNewId(),
        folderId: queryParams.folderId,
        previewMode: queryParams.previewMode,
        backTo: getPageUrl({ presentation, queryParams }),
        backToLabel: `Back to ${presentation?.name}`,
        saveTo: getPageUrl({
          presentation,
          queryParams,
          playlistIdPath: selectedPlaylistPath,
        }),
      }),
    );
  }, [presentation, history, queryParams, selectedPlaylistPath]);

  const canProfileReadPlaylist = useCallback(
    (playlistId: string, profileId: string) => {
      // Return true if user doesn't belong to a domain
      if (!domain) return true;
      const playlist = playlistsById[playlistId];
      if (!playlist) return true;
      const profile = domain.r.profiles.find((p) => p.id === profileId);
      if (!profile) return false;
      return canReadResource(profile, playlist.resource);
    },
    [domain, playlistsById],
  );

  const showReadResourceWarning = useCallback(async (): Promise<boolean> => {
    return new Promise((resolve) => {
      resolveReadResourceWarningRef.current = (result: boolean) => {
        resolveReadResourceWarningRef.current = null;
        setIsReadResourceWarningOpen(false);
        resolve(result);
      };

      setIsReadResourceWarningOpen(true);
    });
  }, []);

  const selectPlaylist = useCallback(
    async (playlistId: string) => {
      if (!presentation) return;

      if (
        !canProfileReadPlaylist(playlistId, presentation.resource.profile.id)
      ) {
        const result = await showReadResourceWarning();
        if (!result) return;
      }

      onSelect(playlistId);
      onClose();
    },
    [
      presentation,
      onSelect,
      onClose,
      canProfileReadPlaylist,
      showReadResourceWarning,
    ],
  );

  // Sife-effects

  // Set selected profile if not set when current user loads.
  useEffect(() => {
    if (!currentUser) return;
    if (selectedProfileId) return;
    setSelectedProfileId(currentUser.id);
  }, [selectedProfileId, currentUser]);

  // Render

  const isModalOpen = selectedPlaylistPath.length > 0;

  return (
    <>
      <PaperModal
        color="lightGrey"
        className={classes.modal}
        open={isModalOpen}
        onClose={onClose}
      >
        {isModalOpen && selectedProfileId && (
          <PlaylistSelector
            selectedProfileId={selectedProfileId}
            onSelectProfile={setSelectedProfileId}
            onSelectPlaylist={selectPlaylist}
            onNewPlaylist={newPlaylist}
            onNewPresentation={newPresentation}
          />
        )}
      </PaperModal>

      <ReadResourceWarning
        ownerResourceType="presentation"
        resourceType="playlist"
        open={isReadResourceWarningOpen}
        onConfirm={resolveReadResourceWarningRef.current}
      />
    </>
  );
};

export default PlaylistSelectorModal;
