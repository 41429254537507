import MUIPopover, {
  PopoverProps as MUIPopoverProps,
} from '@material-ui/core/Popover';
import cn from 'classnames';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

export interface PopoverProps extends MUIPopoverProps {
  variant?: 'default' | 'alert' | 'list';
}

const Popover = ({
  variant = 'default',
  className,
  ...props
}: PopoverProps) => {
  const classes = useStyles();
  return (
    <MUIPopover
      {...props}
      classes={{
        root: classes.popover,
        paper: cn(classes.popoverPaper, classes[variant], className),
      }}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    popover: {
      backgroundColor: theme.modal.overlayBackground,

      '& $popoverPaper': {
        borderRadius: theme.borderRadius.lg,
        minWidth: 128,
        maxWidth: 300,
      },
    },

    popoverPaper: {},

    default: {},

    alert: {
      padding: theme.spacing(2),
      '&$popoverPaper': {
        maxWidth: 390,
      },
    },

    list: {
      padding: theme.spacing(1, 0),
    },
  });
});

export default Popover;
