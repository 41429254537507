import { ActionType, getType } from 'typesafe-actions';
import * as fileUploadActions from '../actions/fileUploads';
import { LocalFileUploads } from '../types';

export type FileUploadActions = ActionType<typeof fileUploadActions>;

export type FileUploadState = Readonly<{
  uploads: LocalFileUploads;
  uploadsByPresentationId: {
    [presentationId: string]: {
      [fileUploadId: string]: {
        path: Array<string | number>;
        fileUploadId: string;
      };
    };
  };
  isFetching: boolean;
  error: string;
}>;

const initialFileUploadsState: FileUploadState = {
  uploads: {},
  uploadsByPresentationId: {},
  isFetching: false,
  error: '',
};

export default function fileUploadsReducer(
  state = initialFileUploadsState,
  action: FileUploadActions,
): FileUploadState {
  switch (action.type) {
    case getType(fileUploadActions.uploadPresentationFileAsync.request): {
      const { fileUploadId, presentationId, path, localUrl } = action.payload;
      return {
        ...state,
        uploads: {
          ...state.uploads,
          [fileUploadId]: {
            isUploading: true,
            localUrl,
          },
        },
        uploadsByPresentationId: {
          ...state.uploadsByPresentationId,
          [presentationId]: {
            ...state.uploadsByPresentationId[presentationId],
            [fileUploadId]: {
              path,
              fileUploadId,
            },
          },
        },
      };
    }

    case getType(fileUploadActions.uploadFileAsync.request): {
      const { fileUploadId, localUrl } = action.payload;
      return {
        ...state,
        uploads: {
          ...state.uploads,
          [fileUploadId]: {
            isUploading: true,
            localUrl,
          },
        },
      };
    }

    case getType(fileUploadActions.uploadFileAsync.success):
      const { fileUploadId } = action.payload;
      return {
        ...state,
        uploads: {
          ...state.uploads,
          [fileUploadId]: {
            ...state.uploads[fileUploadId],
            isUploading: false,
          },
        },
      };

    case getType(fileUploadActions.uploadFileAsync.failure): {
      const { fileUploadId, error } = action.payload;
      return {
        ...state,
        uploads: {
          ...state.uploads,
          [fileUploadId]: {
            ...state.uploads[fileUploadId],
            isUploading: false,
            error,
          },
        },
      };
    }

    default:
      return state;
  }
}
