type FileOptions = {
  type?: string;
  lastModified?: number;
};
export const mapFilesToFileList = (files: File[]): FileList => {
  let dataTransfer = new DataTransfer();
  files.forEach((file) => dataTransfer.items.add(file));

  return dataTransfer.files;
};

export const parseUrlToFile = (
  url: string,
  name: string,
  options?: FileOptions,
): File => new File([url], name, options);

export const parseUrlToFileList = (
  url: string,
  name: string,
  options?: FileOptions,
): FileList => mapFilesToFileList([parseUrlToFile(url, name, options)]);

export const mapFileToFileUpload = (file: File) => {
  return {
    contentType: file.type,
    contentLength: file.size,
    completedSteps: [],
    createdAt: '',
    filename: file.name,
    id: '',
    url: URL.createObjectURL(file),
  };
};
