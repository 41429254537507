import { FC, useState } from 'react';
import Button from 'raydiant-elements/core/Button';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import CalendarClockIcon from 'raydiant-elements/icons/CalendarClock';
import apiClient from '../../../clients/miraClient';
import CircularProgress from 'raydiant-elements/core/CircularProgress';
import Center from 'raydiant-elements/layout/Center';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import Link from 'raydiant-elements/core/Link';
import { useDispatch } from 'react-redux';
import { loadAccountPage } from '../actions';

interface EnableTimezoneProps {
  defaultStatus?: 'idle';
  domainUser?: boolean;
}

const EnableTimezone: FC<EnableTimezoneProps> = ({
  defaultStatus = 'idle',
  domainUser = false,
}) => {
  const dispatch = useDispatch();
  const [openDialogue, setOpenDialogue] = useState<boolean>(false);
  const [status, setStatus] = useState<'idle' | 'error' | 'loading'>(
    defaultStatus,
  );
  const handleOpenDialog = () => setOpenDialogue(true);
  const handleCancel = () => setOpenDialogue(false);
  const handleConfirm = async () => {
    setStatus('loading');

    try {
      await apiClient.enableMultiTimezone(domainUser);
      setOpenDialogue(false);
      dispatch(loadAccountPage());
    } catch (error) {
      setStatus('idle');
    }
  };

  const showLoading = status === 'loading';
  const showIdle = status === 'idle';

  return (
    <>
      <div>
        <Button
          color="primary"
          icon={<CalendarClockIcon />}
          label="Enable multi-timezone scheduling"
          fullWidth
          onClick={handleOpenDialog}
        />
        <InputHelperText indent>
          Use device time zones for playlist scheduling{' '}
          <Link
            href="https://support.raydiant.com/hc/en-us/articles/4534416095764/"
            target="_blank"
          >
            Learn more
          </Link>
        </InputHelperText>
      </div>
      <Dialog
        open={openDialogue}
        TransitionProps={{
          onExited: () => {
            setStatus('idle');
          },
        }}
      >
        {showLoading && (
          <DialogContent>
            <DialogContentText>
              <Center>
                <CircularProgress size={30} />
              </Center>
            </DialogContentText>
          </DialogContent>
        )}
        {showIdle && (
          <>
            <DialogTitle>Multi-timezone scheduling</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Enable this feature if you schedule content across a range of
                time zones and expect schedules to adhere to screen time ie.
                content scheduled for playback at 8AM in your location will play
                back at 8AM in San Francisco, Tokyo and other time zones. Make
                sure you've set the correct time zone for each of your screens
                using the Screen Settings page. Once enabled, you'll need to
                republish all of your playlists for this feature to take effect.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancel} color="default">
                Cancel
              </Button>
              <Button
                onClick={handleConfirm}
                color="primary"
                icon={<CalendarClockIcon />}
              >
                Enable
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default EnableTimezone;
