import { Component } from 'react';
import { connect } from 'react-redux';
import * as userActions from '../../actions/user';
import miraClient from '../../clients/miraClient';
import FloatingLogo from '../../components/FloatingLogo';
import Page from '../../components/Page';
import UnsubscribeConfirmation from '../../components/UnsubscribeConfirmation';
import logger from '../../logger';
import mapStateToProps from './selectors';

interface UnsubscribePageProps {
  profileId: string;
  getProfile: typeof userActions.getProfile;
}

interface UnsubscribePageState {
  success: boolean;
  error: boolean;
}

class UnsubscribePage extends Component<
  UnsubscribePageProps,
  UnsubscribePageState
> {
  state: UnsubscribePageState = {
    success: false,
    error: false,
  };

  async componentDidMount() {
    const { profileId, getProfile } = this.props;
    if (profileId) {
      try {
        await miraClient.unsubscribe(profileId);
        getProfile();
        this.setState({ success: true, error: false });
      } catch (err: any) {
        logger.error(err);
        this.setState({ success: false, error: true });
      }
    } else {
      logger.error(
        new Error('Missing profileId query parameter to unsubscribe.'),
      );
      this.setState({ success: false, error: true });
    }
  }

  render() {
    const { success, error } = this.state;
    return (
      <Page hideNavigation>
        <FloatingLogo />
        {success && <UnsubscribeConfirmation />}
        {error && <UnsubscribeConfirmation error />}
      </Page>
    );
  }
}

export default connect(mapStateToProps, { ...userActions })(UnsubscribePage);
