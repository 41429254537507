import { call, fork, put, take } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import {
  closedChargebeePortal,
  openChargebeePortal,
  openChargebeePortalAsync,
} from '../actions/chargebee';
import { ChargebeePortalSession } from '../clients/chargebee/ChargebeeClient';
import chargebeeClient from '../clients/chargebeeClient';
import miraClient from '../clients/miraClient';
import logger from '../logger';

const openChargebeePortalAndWaitForClose = function* () {
  yield put(openChargebeePortalAsync.request());
  try {
    const portalSession: ChargebeePortalSession = yield call(() =>
      miraClient.generateChargebeePortal(),
    );
    yield call(() => chargebeeClient.openPortal(portalSession));
    yield put(openChargebeePortalAsync.success());
    yield call(
      () => new Promise((resolve) => chargebeeClient.once('close', resolve)),
    );
    yield put(closedChargebeePortal());
  } catch (error: any) {
    logger.error(error);
    yield put(openChargebeePortalAsync.failure(error));
  }
};

const watchOpenChargebeePortal = function* () {
  while (true) {
    yield take(getType(openChargebeePortal));
    yield call(openChargebeePortalAndWaitForClose);
  }
};

export default fork(watchOpenChargebeePortal);
