import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import * as React from 'react';

export const PlaylistCardOutline: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 166 98">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-2340 -199) translate(2343 202)">
        <rect
          width="163"
          height="95"
          x="-1.5"
          y="-1.5"
          stroke="#000"
          strokeOpacity=".7"
          strokeWidth="3"
          rx="1"
        />
        <rect width="159" height="91" x=".5" y=".5" stroke="#FFF" rx="1" />
      </g>
    </g>
  </SvgIcon>
);

export default PlaylistCardOutline;
