import Paper from 'raydiant-elements/core/Paper';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import { FC } from 'react';
import RegisterDevice from '../../components/RegisterDevice';
import deviceCardSkeletonSvg from '../../assets/device-card-skeleton.svg';

interface DeviceEmptyStateProps {}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      flex: 1,
      display: 'flex',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(1),

      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
    },

    inner: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundImage: `url(${deviceCardSkeletonSvg})`,
      backgroundPosition: 'top center',
      backgroundRepeat: 'repeat',
      backgroundSize: '100%',
      padding: theme.spacing(4, 0),

      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0),
        backgroundImage: 'none',
      },
    },

    register: {
      width: '100%',
      maxWidth: 400,
      borderRadius: theme.borderRadius.lg,
      padding: theme.spacing(4),
    },
  });
});

const DeviceEmptyState: FC<DeviceEmptyStateProps> = () => {
  const classes = useStyles();

  // Selectors

  // Render

  return (
    <div className={classes.root}>
      <div className={classes.inner}>
        <Paper className={classes.register} color="light" elevation={3}>
          <RegisterDevice title="Let’s activate your first screen!" />
        </Paper>
      </div>
    </div>
  );
};

export default DeviceEmptyState;
