import { FC } from 'react';
import { SnackbarProvider as NSSnackbarProvider } from 'notistack';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

const SnackbarProvider: FC = ({ children }) => {
  const classes = useStyles();
  return (
    <NSSnackbarProvider
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
    >
      {children}
    </NSSnackbarProvider>
  );
};

const useStyles = makeStyles((_: Theme) => {
  return createStyles({
    success: {
      // notistack uses !important so we need to as well.
      backgroundColor: '#009bd2 !important',
    },

    error: {},

    warning: {},

    info: {},
  });
});

export default SnackbarProvider;
