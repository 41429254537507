import Button from 'raydiant-elements/core/Button';
import Link from 'raydiant-elements/core/Link';
import Center from 'raydiant-elements/layout/Center';
import Text from 'raydiant-elements/typography/Text';
import { Component } from 'react';
import { connect } from 'react-redux';
import * as userActions from '../../actions/user';
import auth0 from '../../clients/auth0Client';
import Page from '../../components/Page';
import mapStateToProps from './selectors';

interface LegacyChangePasswordBackstopPageProps {
  username: string;
}

interface LegacyChangePasswordBackstopPageState {
  status: null | 'sending' | 'error' | 'success';
}

class LegacyChangePasswordBackstopPage extends Component<
  LegacyChangePasswordBackstopPageProps,
  LegacyChangePasswordBackstopPageState
> {
  state: LegacyChangePasswordBackstopPageState = {
    status: null,
  };

  componentDidMount() {
    this.sendPasswordResetEmail();
  }

  sendPasswordResetEmail = async () => {
    const { username } = this.props;
    if (!username) return;

    this.setState({ status: 'sending' });

    try {
      await auth0.sendChangePasswordEmail(username);
      this.setState({ status: 'success' });
    } catch (err) {
      this.setState({ status: 'error' });
    }
  };

  render() {
    const { status } = this.state;
    const isSending = status === 'sending';
    const didError = status === 'error';

    return (
      <Page hideNavigation>
        <Center>
          <img
            src="https://assets.raydiant.com/raydiant-logo-square-lime-green.svg"
            alt=""
            style={{ width: 60, height: 60 }}
          />
          <br />
          <br />
          <div style={{ maxWidth: 290, textAlign: 'center' }}>
            <Text>
              Raydiant has upgraded our login page, and the original link you
              received to set your password will no longer work. We just sent
              you an email with a new link to set your password; please check
              your inbox!
            </Text>
            <br />
            <br />
            <br />
            <br />
            <Button
              fullWidth
              color="primary"
              label={isSending ? 'Sending...' : 'Re-send Set Password Email'}
              onClick={this.sendPasswordResetEmail}
            />
            <br />
            <br />
            {didError && (
              <div>
                Oops! Something went wrong. Please contact{' '}
                <Link href="mailto:support@raydiant.com">
                  support@raydiant.com
                </Link>
                .
              </div>
            )}
            <br />
            <Link
              target="_blank"
              href="https://support.raydiant.com/hc/en-us/articles/360044252274"
            >
              Click here to learn more about the upgrade.
            </Link>
          </div>
        </Center>
      </Page>
    );
  }
}

export default connect(mapStateToProps, { ...userActions })(
  LegacyChangePasswordBackstopPage,
);
