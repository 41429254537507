import { all, call, fork, select, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import * as deviceActions from '../../actions/devices';
import * as playlistActions from '../../actions/playlists';
import * as presActions from '../../actions/presentations';
import analytics from '../../clients/analyticsClient';
import { DevicesById, selectDevicesById } from '../../selectors/v2/devices';
import { selectPlaylistsById } from '../../selectors/v2/playlists';
import {
  PresentationsById,
  selectPresentationsById,
} from '../../selectors/v2/presentations';
import comparePlaylists from './comparePlaylists';
import comparePresentations from './comparePresentations';

type RegisterDeviceRequestPayload = ReturnType<
  typeof deviceActions.registerDeviceAsync.request
>;
function* trackRegisterDeviceRequest() {
  yield takeEvery(
    getType(deviceActions.registerDeviceAsync.request),
    function* ({ payload: { activationCode } }: RegisterDeviceRequestPayload) {
      yield call(() =>
        analytics.trackRegisterDeviceRequest({ activationCode }),
      );
    },
  );
}

type RegisterDeviceSuccessPayload = ReturnType<
  typeof deviceActions.registerDeviceAsync.success
>;
function* trackRegisterDeviceSuccess() {
  yield takeEvery(
    getType(deviceActions.registerDeviceAsync.success),
    function* ({ payload: { id, isAudioOnly } }: RegisterDeviceSuccessPayload) {
      yield call(() =>
        analytics.trackRegisterDeviceSuccess({
          deviceId: id,
          isAudioOnly,
        }),
      );
    },
  );
}

type RegisterDeviceFailurePayload = ReturnType<
  typeof deviceActions.registerDeviceAsync.failure
>;
function* trackRegisterDeviceFailure() {
  yield takeEvery(
    getType(deviceActions.registerDeviceAsync.failure),
    function* ({ payload: { activationCode } }: RegisterDeviceFailurePayload) {
      yield call(() =>
        analytics.trackRegisterDeviceFailure({ activationCode }),
      );
    },
  );
}

type CreatePresentationSuccessPayload = ReturnType<
  typeof presActions.createPresentationAsync.success
>;
function* trackCreatePresentationSuccess() {
  yield takeEvery(
    getType(presActions.createPresentationAsync.success),
    function* ({ payload: presentation }: CreatePresentationSuccessPayload) {
      const compareResults = comparePresentations(presentation);

      const trackSelectedPlaylists = compareResults.newSelectedPlaylists.map(
        (playlistId) =>
          call(() =>
            analytics.trackSelectPlaylist({
              playlistId,
              presentationId: presentation.id,
              applicationId: presentation.applicationId,
            }),
          ),
      );

      yield all([
        ...trackSelectedPlaylists,
        call(() =>
          analytics.trackCreatePresentationSuccess({
            presentationId: presentation.id,
            applicationId: presentation.applicationId,
            appVersionId: presentation.appVersionId,
          }),
        ),
      ]);
    },
  );
}

type UpdatePresentationSuccessPayload = ReturnType<
  typeof presActions.updatePresentationAsync.request
>;
function* trackUpdatePresentationRequest() {
  yield takeEvery(
    // We're tracking updatePresentationAsync.request instead of .success in order to
    // compare the old playlist with the new playlist.
    getType(presActions.updatePresentationAsync.request),
    function* ({ payload: presentation }: UpdatePresentationSuccessPayload) {
      const presentationsById: PresentationsById = yield select(
        selectPresentationsById,
      );
      const oldPresentation = presentationsById[presentation.id];
      const compareResults = comparePresentations(
        presentation,
        oldPresentation,
      );

      const trackSelectedPlaylists = compareResults.newSelectedPlaylists.map(
        (playlistId) =>
          call(() =>
            analytics.trackSelectPlaylist({
              playlistId,
              presentationId: presentation.id,
              applicationId: presentation.applicationId,
            }),
          ),
      );

      yield all([
        ...trackSelectedPlaylists,
        call(() =>
          analytics.trackUpdatePresentationSuccess({
            presentationId: presentation.id,
            applicationId: presentation.applicationId,
            appVersionId: presentation.appVersionId,
          }),
        ),
      ]);
    },
  );
}

type CreatePlaylistSuccessPayload = ReturnType<
  typeof playlistActions.createPlaylistAsync.success
>;
function* trackCreatePlaylistSuccess() {
  yield takeEvery(
    getType(playlistActions.createPlaylistAsync.success),
    function* ({
      payload: { id, startDatetime },
    }: CreatePlaylistSuccessPayload) {
      if (startDatetime) {
        yield call(() =>
          analytics.trackCreateScheduledPlaylistSuccess({
            playlistId: id,
          }),
        );
      } else {
        yield call(() =>
          analytics.trackCreatePlaylistSuccess({
            playlistId: id,
          }),
        );
      }
    },
  );
}

type UpdatePlaylistRequestPayload = ReturnType<
  typeof playlistActions.updatePlaylistAsync.request
>;
function* trackUpdatePlaylistRequest() {
  yield takeEvery(
    // We're tracking updatePlaylistAsync.request instead of .success in order to
    // compare the old playlist with the new playlist.
    getType(playlistActions.updatePlaylistAsync.request),
    function* ({ payload: playlist }: UpdatePlaylistRequestPayload) {
      const playlistsById: PresentationsById = yield select(
        selectPlaylistsById,
      );
      const presentationsById: PresentationsById = yield select(
        selectPresentationsById,
      );
      const oldPlaylist = playlistsById[playlist.id];

      const compareResults = comparePlaylists(
        playlist,
        oldPlaylist || {},
        presentationsById as any,
      );

      if (playlist.startDatetime) {
        yield call(() =>
          analytics.trackUpdateScheduledPlaylistSuccess({
            playlistId: playlist.id,
            ...compareResults,
          }),
        );
      } else {
        yield call(() =>
          analytics.trackUpdatePlaylistSuccess({
            playlistId: playlist.id,
            ...compareResults,
          }),
        );
      }
    },
  );
}

type DeletePlaylistSuccessPayload = ReturnType<
  typeof playlistActions.deletePlaylistAsync.success
>;
function* trackDeletePlaylistSuccess() {
  yield takeEvery(
    getType(playlistActions.deletePlaylistAsync.success),
    function* ({ payload: playlistId }: DeletePlaylistSuccessPayload) {
      // NOTE: Only scheduled playlists can currently be deleted.
      yield call(() =>
        analytics.trackDeleteScheduledPlaylistSuccess({
          playlistId,
        }),
      );
    },
  );
}

type PublishDevicePayload = ReturnType<
  typeof deviceActions.publishDeviceAsync.success
>;
function* trackPublishPlaylistSuccess() {
  yield takeEvery(
    getType(deviceActions.publishDeviceAsync.success),
    function* ({ payload: { id, playlistId } }: PublishDevicePayload) {
      if (playlistId) {
        yield call(() =>
          analytics.trackPublishPlaylistSuccess({
            deviceId: id,
            playlistId,
          }),
        );
      }
    },
  );
}

type UpdateDevicePayload = ReturnType<
  typeof deviceActions.updateDeviceAsync.request
>;
function* trackUpdateDeviceRequest() {
  yield takeEvery(
    getType(deviceActions.updateDeviceAsync.request),
    function* ({ payload: { id, playlistId } }: UpdateDevicePayload) {
      const devicesById: DevicesById = yield select(selectDevicesById);
      const oldDevice = devicesById[id];

      if (oldDevice && oldDevice.playlistId !== playlistId && playlistId) {
        yield call(() =>
          analytics.trackSelectPlaylist({
            deviceId: id,
            playlistId,
          }),
        );
      }
    },
  );
}

export default all([
  fork(trackRegisterDeviceRequest),
  fork(trackRegisterDeviceSuccess),
  fork(trackRegisterDeviceFailure),
  fork(trackCreatePresentationSuccess),
  fork(trackUpdatePresentationRequest),
  fork(trackCreatePlaylistSuccess),
  fork(trackUpdatePlaylistRequest),
  fork(trackPublishPlaylistSuccess),
  fork(trackDeletePlaylistSuccess),
  fork(trackUpdateDeviceRequest),
]);
