import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
export default function useUploadErrorSnackbar() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleUploadError = useCallback(
    (errorMessage: string) => {
      const action = () => (
        <IconButton
          color="inherit"
          onClick={() => {
            closeSnackbar();
          }}
        >
          <HighlightOffIcon />
        </IconButton>
      );
      enqueueSnackbar(errorMessage, {
        variant: 'error',
        autoHideDuration: null,
        action,
      });
    },
    [enqueueSnackbar, closeSnackbar],
  );

  return { handleUploadError };
}
