import { FC, useCallback, useState } from 'react';
import Popover from '../Popover';
import PopoverListItem from '../Popover/PopoverListItem';

export interface LibraryResourceCardActionsProps {
  anchorEl: HTMLButtonElement | null;
  open: boolean;
  hideCopy?: boolean;
  onCopy?: () => void;
  onDelete?: () => void;
  onClose: () => void;
}

const LibraryResourceCardActions: FC<LibraryResourceCardActionsProps> = ({
  anchorEl,
  open,
  hideCopy,
  onCopy,
  onDelete,
  onClose,
}) => {
  // State

  const [isDeletePromptOpen, setIsDeletePromptOpen] = useState(false);

  // Callbacks

  const handleDuplicate = useCallback(() => {
    if (onCopy) {
      onCopy();
    }
    onClose();
  }, [onClose, onCopy]);

  const handleDelete = useCallback(() => {
    if (onDelete) {
      onDelete();
    }
    onClose();
  }, [onClose, onDelete]);

  const handleClose = useCallback(() => {
    setIsDeletePromptOpen(false);
    onClose();
  }, [onClose]);

  // Render

  return (
    <Popover
      variant="list"
      open={open && !isDeletePromptOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      {!hideCopy && (
        <PopoverListItem onClick={handleDuplicate} disabled={!onCopy}>
          Duplicate
        </PopoverListItem>
      )}
      <PopoverListItem onClick={handleDelete} disabled={!onDelete}>
        Delete
      </PopoverListItem>
    </Popover>
  );
};

export default LibraryResourceCardActions;
