import { FC, MouseEventHandler } from 'react';
import cn from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import TagIcon from 'raydiant-elements/icons/Tag';
import RuleIcon from 'raydiant-elements/icons/Rule';
import CalendarClockIcon from 'raydiant-elements/icons/CalendarClock';
import Row from 'raydiant-elements/layout/Row';
import PlaylistItemMoreActionsButton from './PlaylistItemMoreActionsButton';
import usePlaylistPageContext from './usePlaylistPageContext';
import { ItemIDPath } from './playlistPageTypes';
import { isIDPathEqual } from './usePlaylistPageState/utilities';

interface PlaylistItemPlaylistActionsProps {
  itemIdPath: ItemIDPath;
  expanded?: boolean;
  expandedDisabled?: boolean;
  moreActionsOpen?: boolean;
  onToggleExpandClick?: MouseEventHandler<HTMLButtonElement>;
  onMoreClick?: MouseEventHandler<HTMLButtonElement>;
  onScheduleClick?: MouseEventHandler<HTMLButtonElement>;
  onRulesClick?: MouseEventHandler<HTMLButtonElement>;
  onTagsClick?: MouseEventHandler<HTMLButtonElement>;
}

const PlaylistItemPlaylistActions: FC<PlaylistItemPlaylistActionsProps> = ({
  itemIdPath,
  expanded,
  expandedDisabled,
  moreActionsOpen,
  onToggleExpandClick,
  onMoreClick,
  onScheduleClick,
  onRulesClick,
  onTagsClick,
}) => {
  const {
    classes,
    state: { modalItemPath },
  } = usePlaylistPageContext();

  const renderSecondaryActions = onScheduleClick || onRulesClick || onTagsClick;

  const isMoreActionsSelected =
    moreActionsOpen ||
    (!!modalItemPath && isIDPathEqual(itemIdPath, modalItemPath));

  return (
    <div className={classes.playlistItemActions}>
      {renderSecondaryActions && (
        <Row
          inline
          halfMargin
          center
          className={classes.playlistItemActionsSecondary}
        >
          {onScheduleClick && (
            <div>
              <IconButton
                className={classes.playlistItemButton}
                size="small"
                onClick={onScheduleClick}
              >
                <CalendarClockIcon color="inherit" style={{ padding: 2 }} />
              </IconButton>
            </div>
          )}

          {onRulesClick && (
            <div>
              <IconButton
                className={classes.playlistItemButton}
                size="small"
                onClick={onRulesClick}
              >
                <RuleIcon color="inherit" style={{ padding: 2 }} />
              </IconButton>
            </div>
          )}

          {onTagsClick && (
            <div>
              <IconButton
                className={classes.playlistItemButton}
                size="small"
                onClick={onTagsClick}
              >
                <TagIcon color="inherit" style={{ padding: 2 }} />
              </IconButton>
            </div>
          )}
        </Row>
      )}

      <Row inline halfMargin center>
        {onToggleExpandClick && (
          <div>
            <IconButton
              className={cn(
                classes.playlistItemButton,
                classes.playlistItemButtonPrimary,
              )}
              size="small"
              disabled={expandedDisabled}
              onClick={onToggleExpandClick}
            >
              <ArrowDropDownIcon
                color="inherit"
                className={cn(
                  classes.expandIndicator,
                  expanded && classes.expandIndiciatorExpanded,
                )}
              />
            </IconButton>
          </div>
        )}

        {onMoreClick && (
          <div>
            <PlaylistItemMoreActionsButton
              selected={isMoreActionsSelected}
              onClick={onMoreClick}
            />
          </div>
        )}
      </Row>
    </div>
  );
};

export default PlaylistItemPlaylistActions;
