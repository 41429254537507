import FilterListIcon from '@material-ui/icons/FilterList';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import SwapVertReverseIcon from 'raydiant-elements/icons/SwapVertReverse';
import ActionBar from 'raydiant-elements/core/ActionBar/v2';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SortFolderOptions } from '../../utilities';
import { selectSideSortOptions } from './selectors';
import * as actions from './actions';

interface LibrarySideActionBarProps {
  sortDisabled: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),

      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(0),
      },
    },
  }),
);

const LibrarySideActionBar: FC<LibrarySideActionBarProps> = ({
  sortDisabled,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  // Selectors

  const sortOptions = useSelector(selectSideSortOptions);

  // State

  const [isSortMode, setIsSortMode] = useState(false);

  // Callbacks

  const toggleSort = useCallback(
    (property: SortFolderOptions['property']) => {
      let direction: SortFolderOptions['direction'] =
        property === 'updatedAt' ? 'desc' : 'asc';

      if (property === sortOptions.property) {
        direction = sortOptions.direction === 'asc' ? 'desc' : 'asc';
      }

      dispatch(actions.setSideSortOptions({ property, direction }));
      setIsSortMode(false);
    },
    [dispatch, setIsSortMode, sortOptions],
  );

  // Render

  let sortLabel = 'Sort';
  if (sortOptions.property === 'name') {
    sortLabel = `${sortLabel}: Name`;
  } else if (sortOptions.property === 'type') {
    sortLabel = `${sortLabel}: Type`;
  } else if (sortOptions.property === 'updatedAt') {
    sortLabel = `${sortLabel}: Date`;
  }

  const isSortByName = sortOptions.property === 'name';
  const isSortByType = sortOptions.property === 'type';
  const isSortByDate = sortOptions.property === 'updatedAt';
  const isReverseSort = sortOptions.direction === 'desc';

  return (
    <ActionBar className={classes.root}>
      <ActionBar.Select
        icon={<FilterListIcon />}
        label={sortLabel}
        open={isSortMode}
        onOpen={setIsSortMode}
        disabled={sortDisabled}
      >
        <ActionBar.SelectOption
          icon={
            isSortByName && isReverseSort ? (
              <SwapVertReverseIcon />
            ) : (
              <SwapVertIcon />
            )
          }
          label="Name"
          selected={isSortByName}
          onClick={() => toggleSort('name')}
        />
        <ActionBar.SelectOption
          icon={
            isSortByType && isReverseSort ? (
              <SwapVertReverseIcon />
            ) : (
              <SwapVertIcon />
            )
          }
          label="Type"
          selected={isSortByType}
          onClick={() => toggleSort('type')}
        />
        <ActionBar.SelectOption
          icon={
            isSortByDate && isReverseSort ? (
              <SwapVertReverseIcon />
            ) : (
              <SwapVertIcon />
            )
          }
          label="Date"
          selected={isSortByDate}
          onClick={() => toggleSort('updatedAt')}
        />
      </ActionBar.Select>
    </ActionBar>
  );
};

export default LibrarySideActionBar;
