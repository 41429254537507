import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import { buttonReset } from 'raydiant-elements/mixins';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      ...buttonReset(),
      border: `2px solid rgba(0, 0, 0, 0.38)`,
      borderRadius: theme.borderRadius.sm,
      boxShadow: theme.shadows[1],
      fontSize: theme.fontSizes.sm,
      height: 40,
      width: '100%',
      padding: theme.spacing(0, 1),
      paddingRight: theme.spacing(2),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },

    icon: {
      color: theme.palette.text.secondary,
      opacity: 0.5,
    },

    tag: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },

    tagValueInput: {
      // Prevent the tag input from moving 1px down when the tag becomes editable and
      // adds a bottom border. We may want to add this as an option to the Text component
      // in elements if we find the need to do this elsewhere in the codebase.
      borderTop: '1px solid transparent',
      width: '100%',
      flexGrow: 2,
    },

    tagTimeInput: {
      border: 'none',
      outline: 'none',
      flexGrow: 2,
      fontSize: 'inherit',
      fontFamily: 'inherit',
      fontWeight: 'inherit',
      lineHeight: 'inherit',
      borderBottom: `1px dashed ${theme.input.border}`,
      letterSpacing: 'inherit',
      '&:focus': {
        borderBottom: `1px dashed ${theme.input.focusedBorder}`,
      },
    },

    tagPopOverContainer: {
      padding: theme.spacing(3),
    },

    tagInputActionsContainer: {
      padding: theme.spacing(3),
      paddingTop: 0,
    },

    tagInputActions: {
      paddingTop: theme.spacing(3),
      borderTop: `1px solid ${theme.actionBar.border}`,
    },
  }),
);
