import { produce } from 'immer';
import { PlaylistItem } from '@raydiant/api-client-js';
import {
  isNotNullOrUndefined,
  createDefaultPlaylist,
} from '../../../utilities';
import { isNewId } from '../../../utilities/identifiers';
import {
  State,
  Actions,
  ItemIndexPath,
  ItemIDPath,
  UpdatePlaylistWithId,
} from '../playlistPageTypes';
import {
  serializeIDPath,
  getFullPlaylistsById,
  setIndexes,
  isIndexPathEqual,
  isIndexPathDescendent,
  compareIndexPath,
  getPlaylistItemAtIndexPath,
  setDirty,
  setErrors,
  processPendingItems,
  isExpanded,
  collapsePlaylist,
  expandPlaylist,
  getIndexPath,
  getOrderedSelectedPaths,
  orderByIndexPathAsc,
  getParentPlaylistAtIndexPath,
  ensureUnsavedPlaylist,
  ensureUnsavedPlaylistItems,
  setSavedPlaylist,
  removePlaylistItem,
  ensureUnsavedPresentation,
} from './utilities';

// We need to use as any and cast as State because type inference does not
// work well with immer and HTMLElement: https://github.com/immerjs/immer/issues/356
const reducer = produce<any, [Actions]>((state: State, action) => {
  switch (action.type) {
    case 'setSavedPlaylists': {
      for (const playlist of action.playlists) {
        setSavedPlaylist(state, playlist);
      }

      processPendingItems(state);
      setIndexes(state, action.rootPlaylistId);
      setErrors(state, action.rootPlaylistId);
      setDirty(state);

      break;
    }

    case 'setSavedPlaylist': {
      setSavedPlaylist(state, action.playlist);
      processPendingItems(state);
      setIndexes(state, action.rootPlaylistId);
      setErrors(state, action.rootPlaylistId);
      setDirty(state);

      break;
    }

    case 'setSavedPresentations': {
      for (const presentation of action.presentations) {
        state.savedPresentationsById[presentation.id] = presentation;
      }

      break;
    }

    case 'setSavedPresentation': {
      state.savedPresentationsById[action.presentation.id] =
        action.presentation;

      break;
    }

    case 'updatePresentation': {
      const { presentationId, params } = action;
      const unsavedPresentation = ensureUnsavedPresentation(
        state,
        presentationId,
      );

      if (!unsavedPresentation) return;

      if (params.resource?.r?.tags !== undefined) {
        unsavedPresentation.resource = unsavedPresentation.resource || {};
        unsavedPresentation.resource.r = unsavedPresentation.resource.r || {};
        unsavedPresentation.resource.r.tags = params.resource.r.tags;
      }
      setDirty(state);

      break;
    }

    case 'updatePlaylist': {
      const { playlistId, params, rootPlaylistId } = action;

      const unsavedPlaylist = ensureUnsavedPlaylist(state, playlistId);
      if (!unsavedPlaylist) return;

      if (params.name !== undefined) {
        unsavedPlaylist.name = params.name;
      }
      if (params.startDatetime !== undefined) {
        unsavedPlaylist.startDatetime = params.startDatetime;
      }
      if (params.endDatetime !== undefined) {
        unsavedPlaylist.endDatetime = params.endDatetime;
      }
      if (params.tzid !== undefined) {
        unsavedPlaylist.tzid = params.tzid;
      }
      if (params.recurrenceRule !== undefined) {
        unsavedPlaylist.recurrenceRule = params.recurrenceRule;
      }
      if (params.scheduleType !== undefined) {
        unsavedPlaylist.scheduleType = params.scheduleType;
      }
      if (params.rule !== undefined) {
        unsavedPlaylist.rule = params.rule;
      }
      if (params.resource?.r?.tags !== undefined) {
        unsavedPlaylist.resource = unsavedPlaylist.resource || {};
        unsavedPlaylist.resource.r = unsavedPlaylist.resource.r || {};
        unsavedPlaylist.resource.r.tags = params.resource.r.tags;
      }

      if (params.isRuleOnItems !== undefined) {
        unsavedPlaylist.isRuleOnItems = params.isRuleOnItems;
      }

      setErrors(state, rootPlaylistId);
      setDirty(state);

      break;
    }

    case 'createPlaylist': {
      state.newPlaylistsById[action.playlistId] = createDefaultPlaylist({
        id: action.playlistId,
        resource: { profile: { id: action.profileId } },
      });

      processPendingItems(state);
      setIndexes(state, action.rootPlaylistId);
      setErrors(state, action.rootPlaylistId);
      setDirty(state);

      break;
    }

    case 'toggleExpanded': {
      if (isExpanded(state, action.path)) {
        collapsePlaylist(state, action.path, action.playlistId);
      } else {
        expandPlaylist(state, action.path, action.playlistId);
      }
      break;
    }

    case 'toggleSelected': {
      const pathKey = serializeIDPath(action.path);
      if (!state.selectedItems[pathKey]) {
        state.selectedItems[pathKey] = true;
      } else {
        delete state.selectedItems[pathKey];
      }
      state.lastSelectedPath = action.path;
      break;
    }

    case 'setInitialSelected': {
      state.selectedItems = { [serializeIDPath(action.path)]: true };
      state.lastSelectedPath = action.path;
      break;
    }

    case 'setSelectedEnd': {
      const startIdPath = state.lastSelectedPath ?? action.path;
      let startPath = {
        indexPath: getIndexPath(state, startIdPath),
        idPath: startIdPath,
      };

      let endPath = {
        indexPath: getIndexPath(state, action.path),
        idPath: action.path,
      };

      // Swap start path and end path if start path > end path.
      [startPath, endPath] = orderByIndexPathAsc([startPath, endPath]);

      const isSelectingAcrossPlaylists =
        startPath.indexPath.length !== endPath.indexPath.length;
      const playlistsById = getFullPlaylistsById(state);

      let currentIndexPath = [...startPath.indexPath];
      let interations = 0;
      while (compareIndexPath(currentIndexPath, endPath.indexPath) <= 0) {
        interations += 1;
        // TODO: There's currently an inifinite loop bug when, remove iterations when fixed.
        // NOTE: This might not be an issue anymore, we should remove this and test.
        if (interations > 1000) break;

        const [playlistItem, idPath] = getPlaylistItemAtIndexPath(
          playlistsById,
          action.rootPlaylistId,
          currentIndexPath,
        );

        if (!playlistItem || !idPath) {
          // If a playlist item doesn't exist, check the parent.
          currentIndexPath.pop();
          continue;
        }

        // We want to skip over selecting the nested playlist and instead select the items
        // inside it if we are selecting across nested playlists.
        if (!isSelectingAcrossPlaylists || !playlistItem.playlistId) {
          state.selectedItems[serializeIDPath(idPath)] = true;
          currentIndexPath[currentIndexPath.length - 1] += 1;
        } else {
          currentIndexPath.push(0);
        }
      }

      // Set the last selected path.
      state.lastSelectedPath = action.path;

      break;
    }

    case 'moveSelectedItems': {
      const playlistsById = getFullPlaylistsById(state);

      let destinationIndexPath = getIndexPath(state, action.destinationPath);

      // Get the destination item for the index path. This is the item the user
      // dropped the selection on to.
      const [destinationItem] = getPlaylistItemAtIndexPath(
        playlistsById,
        action.rootPlaylistId,
        destinationIndexPath,
      );

      if (!destinationItem) return;

      if (destinationItem.playlistId && action.dropPosition === 'center') {
        // Dropped items on a playlist, move selected items to the beginning of the playlist.
        destinationIndexPath = [...destinationIndexPath, 0];
        // Expand playlist if it's not currently expanded. This will trigger the playlist to
        // be fetched if it hasn't been already.
        if (!isExpanded(state, action.destinationPath)) {
          expandPlaylist(
            state,
            action.destinationPath,
            destinationItem.playlistId,
          );
        }
      }

      // The destination index of the item is the last item in the index path.
      let destinationIndex =
        destinationIndexPath[destinationIndexPath.length - 1];

      if (action.dropPosition === 'bottom') {
        // Dropped items below the item, move selected items after.
        destinationIndex += 1;
      }

      // Get index paths from id paths so we can sort by index to make sure we
      // move items to the destination in the correct order.
      const sortedPaths = getOrderedSelectedPaths(state);

      // Collect all the playlist items that need to be moved to a playlist by their index.
      // This needs to happen before adding the playlists because we are looking up the playlist
      // item to move by the index.
      const itemsToMove: Array<{
        sourcePlaylistItem: PlaylistItem;
        unsavedSourcePlaylist: UpdatePlaylistWithId;
        sourceIndex: number;
        sourceIndexPath: ItemIndexPath;
        sourceIdPath: ItemIDPath;
      }> = [];
      for (const {
        indexPath: sourceIndexPath,
        idPath: sourceIdPath,
      } of sortedPaths) {
        // If path and destination path are equal or the destination is a descendent
        // of the source, do nothing. Otherwise move the playlist item to the destination playlist.
        // Checking for a deescendent path prevents moving a parent playlist into it's child playlist.
        if (
          isIndexPathEqual(sourceIndexPath, destinationIndexPath) ||
          isIndexPathDescendent(sourceIndexPath, destinationIndexPath)
        ) {
          continue;
        }

        // The source index of the item is the last item in the index path.
        const sourceIndex = sourceIndexPath[sourceIndexPath.length - 1];

        // Get the source playlist item we need to move.
        const [sourcePlaylistItem] = getPlaylistItemAtIndexPath(
          playlistsById,
          action.rootPlaylistId,
          sourceIndexPath,
        );
        if (!sourcePlaylistItem) continue;

        // Get the source playlist of the item we need to move.
        const sourcePlaylist = getParentPlaylistAtIndexPath(
          playlistsById,
          action.rootPlaylistId,
          sourceIndexPath,
        );
        if (!sourcePlaylist) continue;

        // Create or get a reference to the playlist in updatedPlaylistsById for the source playlist.
        const unsavedSourcePlaylist = ensureUnsavedPlaylist(
          state,
          sourcePlaylist.id,
        );
        if (!unsavedSourcePlaylist) continue;

        // Copy the source playlist items into the updated playlist if it doesn't already have updated
        // playlist items.
        ensureUnsavedPlaylistItems(unsavedSourcePlaylist, sourcePlaylist);

        itemsToMove.push({
          sourcePlaylistItem,
          unsavedSourcePlaylist,
          sourceIndex,
          sourceIndexPath,
          sourceIdPath,
        });
      }

      // If there are no items to move then return early with existing state. This needs to happen
      // before we call ensureUpdatedParentPlaylistAtIndexPath to prevent creating an unsaved playlist.
      if (itemsToMove.length === 0) return;

      // Get the destination playlist for the destination index path.
      const destinationPlaylist = getParentPlaylistAtIndexPath(
        playlistsById,
        action.rootPlaylistId,
        destinationIndexPath,
      );

      // Get or create a reference to the playlst in updatedPlaylistsById for the destination playlist.
      const unsavedDestinationPlaylist =
        destinationPlaylist &&
        ensureUnsavedPlaylist(state, destinationPlaylist.id);

      if (unsavedDestinationPlaylist && destinationPlaylist) {
        // Copy the destination playlist items into the updated playlist if it doesn't already have updated
        // playlist items.
        ensureUnsavedPlaylistItems(
          unsavedDestinationPlaylist,
          destinationPlaylist,
        );
      }

      // Track which playlist items need to be removed from the source playlist by index.
      const movedItemsByPlaylist: Record<
        string,
        { playlist: UpdatePlaylistWithId; indexes: number[]; offset: number }
      > = {};

      // Track which playlist items were unable to be moved because the destination playlist
      // hasn't been fetched yet.
      const pendingItemsByPlaylist: Record<string, PlaylistItem[]> = {};

      // Then add items to the destination playlist.
      for (const {
        sourcePlaylistItem,
        unsavedSourcePlaylist,
        sourceIndex,
        sourceIndexPath,
      } of itemsToMove) {
        if (unsavedDestinationPlaylist) {
          (unsavedDestinationPlaylist.items || []).splice(
            destinationIndex,
            0,
            sourcePlaylistItem,
          );

          if (!movedItemsByPlaylist[unsavedDestinationPlaylist.id]) {
            movedItemsByPlaylist[unsavedDestinationPlaylist.id] = {
              playlist: unsavedDestinationPlaylist,
              indexes: [],
              offset: 0,
            };
          }

          // If we are moving a item that comes after the destination item we need increment the
          // index offset of the destination playlist to ensure we remove the correct playlist item(s)
          // from the destination playlis after adding items.
          if (compareIndexPath(sourceIndexPath, destinationIndexPath) === 1) {
            movedItemsByPlaylist[unsavedDestinationPlaylist.id].offset += 1;
          }
        } else if (destinationItem.playlistId) {
          // If items were dropped on a playlist item but the destination playlist doesn't exist
          // that means we haven't fetched the playlist yet. Queue up items to be added to the
          // playlist when it has been fetched.
          if (!pendingItemsByPlaylist[destinationItem.playlistId]) {
            pendingItemsByPlaylist[destinationItem.playlistId] = [];
          }

          pendingItemsByPlaylist[destinationItem.playlistId].push(
            sourcePlaylistItem,
          );
        }

        if (!movedItemsByPlaylist[unsavedSourcePlaylist.id]) {
          movedItemsByPlaylist[unsavedSourcePlaylist.id] = {
            playlist: unsavedSourcePlaylist,
            indexes: [],
            offset: 0,
          };
        }

        movedItemsByPlaylist[unsavedSourcePlaylist.id].indexes.push(
          sourceIndex,
        );

        // Increment destination path to add the next item.
        destinationIndex += 1;
      }

      const movedItems = Object.values(movedItemsByPlaylist);

      // Remove items from source playlist(s).
      for (const { playlist, indexes, offset } of movedItems) {
        const indexesWithOffset = indexes.map((i) => i + offset);
        playlist.items = (playlist.items || []).filter(
          (_, i) => indexesWithOffset.indexOf(i) === -1,
        );
      }

      // Queue any playlist items that were unable to be moved because the destination
      // playlist hasn't been fetched yet. We want to add the items to the beginning of
      // the playlist in the correct order.
      const pendingItemEntries = Object.entries(pendingItemsByPlaylist);
      for (const [playlistId, pendingItems] of pendingItemEntries) {
        if (!state.pendingPlaylistItems[playlistId]) {
          state.pendingPlaylistItems[playlistId] = [];
        }
        // Add items to the beginning of the playlist. This also accounts for the case when
        // pending items already exist for this playlist.
        state.pendingPlaylistItems[playlistId] = [
          ...pendingItems,
          ...state.pendingPlaylistItems[playlistId],
        ];
      }

      // Recalculate index paths and set is drity if items have been moved.
      if (movedItems.length > 0) {
        setIndexes(state, action.rootPlaylistId);
        setErrors(state, action.rootPlaylistId);
        setDirty(state);
      }

      // Reset selected and expanded items to their new destination paths
      for (const { sourceIdPath } of itemsToMove) {
        // Get the id path to the destination playlist. If we are moving the source item
        // above or below the destination item then we need to remove the last item from
        // the path to get the path to the parent playlist. If we are moving the source item
        // into a nested playing than action.destinationPath is the path to the destination playlist.
        const playlistDestinationIdPath =
          destinationItem.playlistId && action.dropPosition === 'center'
            ? action.destinationPath
            : action.destinationPath.slice(0, -1);

        const sourceItemId = sourceIdPath[sourceIdPath.length - 1];
        const destinationIdPath = [...playlistDestinationIdPath, sourceItemId];

        const sourceIdKey = serializeIDPath(sourceIdPath);
        const destinationIdKey = serializeIDPath(destinationIdPath);

        delete state.selectedItems[sourceIdKey];
        state.selectedItems[destinationIdKey] = true;

        if (state.expandedItems[sourceIdKey]) {
          delete state.expandedItems[sourceIdKey];
          state.expandedItems[destinationIdKey] = true;
        }
      }

      break;
    }

    case 'submitError': {
      state.errorsById = action.errorsById;
      break;
    }

    case 'submitSuccess': {
      // Playlist item ids change after save. To ensure selected and expanded items
      // persist correctly after saving we figure out their equivalent index path
      // and reset them using the new ids at the given index path.

      const selectedIndexPaths = Object.keys(state.selectedItems)
        .map((pathStr) => state.itemIndexesByItemIdPath[pathStr])
        .filter(isNotNullOrUndefined);

      const expandedIndexPaths = Object.keys(state.expandedItems)
        .map((pathStr) => state.itemIndexesByItemIdPath[pathStr])
        .filter(isNotNullOrUndefined);

      for (const [playlistId, playlist] of Object.entries(
        action.playlistsById,
      )) {
        const newPlaylistId = playlist.id;

        // Remove unsaved playlist using the old playlist id.
        delete state.updatedPlaylistsById[playlistId];
        delete state.newPlaylistsById[playlistId];

        // Set saved playlist using the new playlist id
        state.savedPlaylistsById[newPlaylistId] = playlist;

        // Set expandedPlaylistIds to use the new ids.
        if (state.expandedPlaylistIds[playlistId]) {
          state.expandedPlaylistIds[newPlaylistId] =
            state.expandedPlaylistIds[playlistId];

          delete state.expandedPlaylistIds[playlistId];
        }
      }

      // Reset added items. Items are now saved with the playlist.
      state.addedPresentationIds = {};
      state.addedPlaylistIds = {};

      // Reset selected items with the updated item ids.
      state.selectedItems = {};
      for (const indexPath of selectedIndexPaths) {
        const [, idPath] = getPlaylistItemAtIndexPath(
          state.savedPlaylistsById,
          action.rootPlaylistId,
          indexPath,
        );

        if (idPath) {
          state.selectedItems[serializeIDPath(idPath)] = true;
        }
      }

      // Reset expanded items with the updated item ids.
      state.expandedItems = {};
      for (const indexPath of expandedIndexPaths) {
        const [, idPath] = getPlaylistItemAtIndexPath(
          state.savedPlaylistsById,
          action.rootPlaylistId,
          indexPath,
        );

        if (idPath) {
          state.expandedItems[serializeIDPath(idPath)] = true;
        }
      }

      //reset updated presentations
      state.updatedPresentationsById = {};

      // Reset index paths.
      setIndexes(state, action.rootPlaylistId);

      // Reset is dirty.
      setDirty(state);

      break;
    }

    case 'setPreviewItem': {
      state.previewItem = action.item;
      break;
    }

    case 'openMoreActions': {
      state.moreActionsItem = action.item;
      state.moreActionsItemPath = action.path;
      // We need to cast as HTMLButtonElement because type inference does not work well with immer
      // and HTMLElement: https://github.com/immerjs/immer/issues/356
      (state.moreActionsAnchorEl as HTMLElement) = action.anchorEl;

      const indexPath =
        state.itemIndexesByItemIdPath[serializeIDPath(action.path)];
      if (indexPath) {
        const parentPlaylist = getParentPlaylistAtIndexPath(
          getFullPlaylistsById(state),
          action.rootPlaylistId,
          indexPath,
        );

        state.moreActionsParentPlaylist = parentPlaylist ?? null;
      }
      break;
    }

    case 'closeMoreActions': {
      state.moreActionsAnchorEl = null;
      state.moreActionsItemPath = null;
      break;
    }

    case 'addPlaylistItems': {
      let destinationIndexPath = getIndexPath(state, action.destinationPath);

      const destinationPlaylist = getParentPlaylistAtIndexPath(
        getFullPlaylistsById(state),
        action.rootPlaylistId,
        destinationIndexPath,
      );

      if (!destinationPlaylist) return;

      const unsavedDestinationPlaylist = ensureUnsavedPlaylist(
        state,
        destinationPlaylist.id,
      );

      if (!unsavedDestinationPlaylist) return;

      const unsavedDestinationPlaylistItems = ensureUnsavedPlaylistItems(
        unsavedDestinationPlaylist,
        destinationPlaylist,
      );

      // Add items after the destination path. If the destinationIndexPath is [] then
      // add items at the beginning of the playlist.
      let destinationIndex =
        destinationIndexPath.length > 0
          ? destinationIndexPath[destinationIndexPath.length - 1] + 1
          : 0;

      for (const item of action.items) {
        unsavedDestinationPlaylistItems.splice(destinationIndex, 0, item);

        // Increment destination path to add the next item.
        destinationIndex += 1;

        // Track the added presentation and playlist ids so we can fetch the latest data on page
        // load if we haven't saved the playlist yet. If the playlist isn't saved, the presentation
        // or playlist won't be in the playlist items.
        if (item.presentationId) {
          state.addedPresentationIds[item.presentationId] = true;
          if (item.presentation) {
            state.savedPresentationsById[item.presentationId] =
              item.presentation;
          }
        } else if (item.playlistId) {
          state.addedPlaylistIds[item.playlistId] = true;

          // Add new nested playlist to new playlists.
          if (item.playlist) {
            if (isNewId(item.playlist.id)) {
              state.newPlaylistsById[item.playlist.id] = item.playlist;
            } else {
              state.savedPlaylistsById[item.playlistId] = item.playlist;
            }
          }
        }
      }

      setErrors(state, action.rootPlaylistId);
      setDirty(state);
      setIndexes(state, action.rootPlaylistId);

      break;
    }

    case 'removePlaylistItem': {
      removePlaylistItem(state, action.path, action.rootPlaylistId);
      setIndexes(state, action.rootPlaylistId);
      setErrors(state, action.rootPlaylistId);
      setDirty(state);

      break;
    }

    case 'removeAllPlaylistItemsForPresentation': {
      // Remove each item for the presentation id.
      const itemIdPaths = state.itemIdsByPresentationId[action.presentationId];
      if (itemIdPaths) {
        itemIdPaths.forEach((path) => {
          removePlaylistItem(state, path, action.rootPlaylistId);
          // We need to set indexes for each iteration because removePlaylistItem relies on the
          // new indexes in order to remove the next item.
          setIndexes(state, action.rootPlaylistId);
        });
      }

      setErrors(state, action.rootPlaylistId);
      setDirty(state);

      break;
    }

    case 'removeAllPlaylistItemsForPlaylist': {
      // Remove each item for the playlist id.
      const itemIdPaths = state.itemIdsByPlaylistId[action.playlistId];
      if (itemIdPaths) {
        itemIdPaths.forEach((path) => {
          removePlaylistItem(state, path, action.rootPlaylistId);
          // We need to set indexes for each iteration because removePlaylistItem relies on the
          // new indexes in order to remove the next item.
          setIndexes(state, action.rootPlaylistId);
        });
      }

      setErrors(state, action.rootPlaylistId);
      setDirty(state);

      break;
    }

    case 'openModal': {
      state.modalItem = action.item;
      state.modalItemPath = action.path;
      state.modalItemMode = action.mode;
      break;
    }

    case 'closeModal': {
      state.modalItemPath = null;
      break;
    }

    case 'setEditItemName': {
      state.itemEditNamePath = action.path;
      break;
    }

    case 'resetEditItemName': {
      state.itemEditNamePath = null;
      break;
    }
  }
});

export default reducer;
