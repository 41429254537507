import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import { textTruncate } from 'raydiant-elements/mixins';
import { FC } from 'react';

interface AccountTableProps {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& th': {
        width: '50%',
        fontWeight: 500,
        textAlign: 'left',
        padding: theme.spacing(0.25, 0),
        paddingRight: theme.spacing(0.25),
        color: theme.palette.text.secondary,
        ...textTruncate(),
      },

      '& td': {
        width: '50%',
        padding: theme.spacing(0.25, 0),

        fontSize: theme.fontSizes.lg,
      },

      '& th + td': {
        paddingLeft: theme.spacing(0.25),
      },
    },
  }),
);

const AccountTable: FC<AccountTableProps> = ({ children }) => {
  const classes = useStyles();
  return <table className={classes.root}>{children}</table>;
};

export default AccountTable;
