import { Playlist } from '@raydiant/api-client-js';
import PlaylistItem from './PlaylistItem';
import { ItemIDPath } from './playlistPageTypes';
import usePlaylistPageContext from './usePlaylistPageContext';

interface PlaylistItemListProps {
  playlist: Playlist;
  itemIdPath?: ItemIDPath;
  playlistIdPath?: ItemIDPath;
}

const PlaylistItemList = ({
  playlist,
  itemIdPath = [],
  playlistIdPath = [],
}: PlaylistItemListProps) => {
  const { classes } = usePlaylistPageContext();

  if (playlist.items.length === 0) {
    return <div className={classes.playlistItemsSkeleton} />;
  }

  const playlistItems = playlist.items.map((item) => (
    <PlaylistItem
      key={item.id}
      item={item}
      itemIdPath={[...itemIdPath, item.id]}
      playlistIdPath={[...playlistIdPath, playlist.id]}
      parentPlaylist={playlist}
    />
  ));

  const isRootPlaylist = itemIdPath.length === 0;
  if (isRootPlaylist) {
    return <div className={classes.playlistItems}>{playlistItems}</div>;
  }

  return <>{playlistItems}</>;
};

export default PlaylistItemList;
