export const hasUtmTrackingParameters = (url: string) =>
  !!(
    getUtmTrackingParameters(url, 'utm_campaign') ||
    getUtmTrackingParameters(url, 'utm_medium') ||
    getUtmTrackingParameters(url, 'utm_source')
  );

export const getUtmTrackingParameters = (
  address: string,
  key: 'utm_campaign' | 'utm_medium' | 'utm_source',
) => {
  const url = new URL(address);

  return url.searchParams.get(key);
};
