import { IdToken } from '@auth0/auth0-spa-js';
import querystring from 'querystring';
import { all, call, fork, put, take, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import * as userActions from '../actions/user';
import auth0Client from '../clients/auth0Client';
import * as U from '../clients/mira/types/User';
import miraClient from '../clients/miraClient';
import history from '../history';
import logger from '../logger';
import * as paths from '../routes/paths';
import {
  getMasqueradeUser,
  setMasqueradeUser,
  setRemoteAssistUser,
} from '../utilities';

type SetRemoteAssistanceAction = ReturnType<
  typeof userActions.setRemoteAssistance
>;

export const getProfile = function* () {
  yield put(userActions.getProfileAsync.request());
  try {
    const profile: U.Profile = yield call(() => miraClient.getProfile());
    yield put(userActions.getProfileAsync.success(profile));
  } catch (error: any) {
    logger.error(error);
    yield put(userActions.getProfileAsync.failure(error));
  }
};

const updateProfile = function* (profile: U.Profile) {
  yield put(userActions.updateProfileAsync.request(profile));
  try {
    const updatedProfile: U.Profile = yield call(() =>
      miraClient.updateProfile(profile),
    );
    yield put(userActions.updateProfileAsync.success(updatedProfile));
  } catch (error: any) {
    logger.error(error);
    yield put(userActions.updateProfileAsync.failure(error));
  }
};

export const getRolesAndPreferences = function* () {
  yield put(userActions.getRolesAndPreferencesAsync.request());
  try {
    const masqueradeAsUser = getMasqueradeUser();
    if (masqueradeAsUser) {
      // Masquerading as a user, fetch roles from the masquerade endpoint.
      const { roles } = yield call(() =>
        miraClient.masquerade(masqueradeAsUser),
      );
      yield put(
        userActions.getRolesAndPreferencesAsync.success({
          roles,
          // Masquerading currently doesn't support preferences.
          preferences: {},
        }),
      );
    } else {
      // Call getTokenSilently to ensure there is a token to fetch the claims
      // from. idToken can be undefined if the user explicilty logs out, clearing
      // Auth0's token storage.
      yield call(() => auth0Client.getTokenSilently());
      const idToken: IdToken = yield call(() => auth0Client.getIdTokenClaims());

      const customClaimsNamespace = 'https://dash.raydiant.com';
      const roles = idToken[`${customClaimsNamespace}/roles`] || [];
      const preferences = idToken[`${customClaimsNamespace}/preferences`] || {};

      yield put(
        userActions.getRolesAndPreferencesAsync.success({
          roles,
          preferences: {
            homeTab: preferences.home_tab,
          },
        }),
      );
    }
  } catch (error: any) {
    logger.error(error);
    yield put(userActions.getRolesAndPreferencesAsync.failure(error));
  }
};

const masqueradeUser = (email: string) => {
  setMasqueradeUser(email);
  window.location.href = paths.home();
};

const logoutUser = function* ({
  payload,
}: ReturnType<typeof userActions.logoutUser>) {
  setMasqueradeUser('');
  setRemoteAssistUser('');
  yield call(() => auth0Client.logout(payload.backTo));
};

const watchGetProfile = function* () {
  while (true) {
    yield take(getType(userActions.getProfile));
    yield call(getProfile);
  }
};

const watchUpdateProfile = function* () {
  while (true) {
    const { payload: profile }: { payload: U.Profile } = yield take(
      getType(userActions.updateProfile),
    );
    yield call(updateProfile, profile);
  }
};

const watchMasqueradeUser = function* () {
  while (true) {
    const { payload: username }: { payload: string } = yield take(
      getType(userActions.masqueradeUser),
    );
    yield call(masqueradeUser, username);
  }
};

const watchLogoutUser = function* () {
  yield takeEvery(getType(userActions.logoutUser), logoutUser);
};

const getProfileAndRolesOnPageLoad = function* () {
  // Check if user is masquerading before fetching profile and roles.
  const queryParams = querystring.parse(
    history.location.search.replace('?', ''),
  );

  const masqueradeAsUser =
    typeof queryParams.masquerade === 'string' ? queryParams.masquerade : null;

  if (masqueradeAsUser) {
    setMasqueradeUser(masqueradeAsUser);
  }

  yield call(getProfile);
  yield call(getRolesAndPreferences);
};

const setRemoteAssistance = function* (
  grantLevel: string | null,
  expiresAt: string | null,
) {
  try {
    yield put(userActions.setRemoteAssistanceAsync.request());
    yield call(() => miraClient.setRemoteAssistance(grantLevel, expiresAt));
    yield put(
      userActions.setRemoteAssistanceAsync.success({ grantLevel, expiresAt }),
    );
  } catch (error: any) {
    logger.error(error);
    yield put(userActions.setRemoteAssistanceAsync.failure(error));
  }
};

const watchSetRemoteAssistance = function* () {
  while (true) {
    const { payload }: SetRemoteAssistanceAction = yield take(
      getType(userActions.setRemoteAssistance),
    );
    yield call(setRemoteAssistance, payload.grantLevel, payload.expiresAt);
  }
};

const remoteAssist = (email: string) => {
  setRemoteAssistUser(email);
  window.location.href = paths.home();
};

const watchRemoteAssist = function* () {
  while (true) {
    const { payload: username }: { payload: string } = yield take(
      getType(userActions.remoteAssist),
    );
    yield call(remoteAssist, username);
  }
};

export default all([
  fork(watchGetProfile),
  fork(watchUpdateProfile),
  fork(watchMasqueradeUser),
  fork(watchLogoutUser),
  fork(getProfileAndRolesOnPageLoad),
  fork(watchSetRemoteAssistance),
  fork(watchRemoteAssist),
]);
