import Heading from 'raydiant-elements/core/Heading';
import Column from 'raydiant-elements/layout/Column';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ResourceACL, Device } from '@raydiant/api-client-js';
import ShareResource from '../../components/ShareResource';
import * as deviceActions from '../../actions/devices';

interface DeviceSharingProps {
  device: Device;
}

const DeviceSharing = ({ device }: DeviceSharingProps) => {
  const dispatch = useDispatch();

  // Callbacks

  const shareDevice = useCallback(
    async (profileId: string) => {
      return new Promise<void>((resolve, reject) => {
        dispatch(
          deviceActions.shareDevice(
            {
              deviceId: device.id,
              profileId,
            },
            {
              onShare: () => resolve(),
              onError: reject,
            },
          ),
        );
      });
    },
    [dispatch, device],
  );

  const unShareDevice = useCallback(
    async (selectedACL: ResourceACL) => {
      return new Promise<void>((resolve) => {
        dispatch(
          deviceActions.removeDeviceResourceACL({
            deviceId: device.id,
            aclId: selectedACL.id,
          }),
        );

        resolve();
      });
    },
    [dispatch, device],
  );

  // Render

  return (
    <Column>
      <Heading size={5} overline gutterBottom>
        Sharing
      </Heading>

      <ShareResource
        resource={device.resource}
        resourceType="screen"
        onShare={shareDevice}
        onUnshare={unShareDevice}
      />
    </Column>
  );
};

export default DeviceSharing;
