import Heading5 from 'raydiant-elements/typography/Heading5';
import Text from 'raydiant-elements/typography/Text';
import Input from 'raydiant-elements/core/Input';
import InputLabel from 'raydiant-elements/core/InputLabel';
import InputHelperText from 'raydiant-elements/core/InputHelperText';
import Form from 'raydiant-elements/core/Form';
import Link from 'raydiant-elements/core/Link';
import Button from 'raydiant-elements/core/Button';
import Column from 'raydiant-elements/layout/Column';
import Row from 'raydiant-elements/layout/Row';
import { FC, useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import miraClient from '../../clients/miraClient';
import { getMasqueradeUser } from '../../utilities';
import logger from '../../logger';
import * as userActions from '../../actions/user';

interface MasqueradeProps {}

const Masquerade: FC<MasqueradeProps> = () => {
  const dispatch = useDispatch();
  const currentMasqueradeUser = getMasqueradeUser();

  // State

  const [masqueradeEmail, setMasqueradeEmail] = useState<string | null>(null);
  const [checkValidEmailStatus, setCheckValidEmailStatus] = useState<
    '' | 'pending' | 'success' | 'error'
  >('');

  // Callbacks

  const masquerade = useCallback(async () => {
    if (!masqueradeEmail) return;

    setCheckValidEmailStatus('pending');
    try {
      // Check if user is valid using the masquerade endpoint to validate the
      // email address. We don't care about the response here since masquerading
      // actually happens on the next page load.
      await miraClient.masquerade(masqueradeEmail);
      dispatch(userActions.masqueradeUser(masqueradeEmail));
      setCheckValidEmailStatus('success');
    } catch (err: any) {
      logger.error(err);
      setCheckValidEmailStatus('error');
    }
  }, [dispatch, masqueradeEmail]);

  const resetMasqueradeUser = useCallback(() => {
    dispatch(userActions.masqueradeUser(''));
  }, [dispatch]);

  // Side-effects

  // Reset error when masquerade email changes.
  useEffect(() => {
    setCheckValidEmailStatus('');
  }, [masqueradeEmail]);

  // Render

  return (
    <>
      <Heading5 overline>Masquerade</Heading5>
      <Column>
        {currentMasqueradeUser && (
          <Text small>
            You are masquerading as {currentMasqueradeUser}.<br />
            <Link onClick={resetMasqueradeUser}>
              Go back to original account.
            </Link>
          </Text>
        )}

        <Form onSubmit={masquerade}>
          <InputLabel error={checkValidEmailStatus === 'error'}>
            Target email
          </InputLabel>
          <Row halfMargin>
            <Input
              type="email"
              onChange={setMasqueradeEmail}
              value={masqueradeEmail ?? ''}
              error={checkValidEmailStatus === 'error'}
            />
            <Button
              label="Masquerade"
              color="progress"
              type="submit"
              disabled={!masqueradeEmail || checkValidEmailStatus === 'pending'}
            />
          </Row>
          {checkValidEmailStatus === 'error' && (
            <InputHelperText error indent>
              User does not exist
            </InputHelperText>
          )}
        </Form>
      </Column>
    </>
  );
};

export default Masquerade;
