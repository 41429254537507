import { Component } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { Theme as RETheme } from 'raydiant-elements/theme';
import withStyles, {
  createStyles,
  WithStyles,
} from '../../components/withStyles';

interface ThemeOptionItemProps extends WithStyles<typeof styles> {
  value: string;
}

// Needs to be a class component to wrap MUI's MenuItem component.
class ThemeOptionItem extends Component<ThemeOptionItemProps> {
  render() {
    const { classes, ...rest } = this.props;
    return <MenuItem {...rest} classes={{ root: classes.root }} />;
  }
}

const styles = (theme: RETheme) =>
  createStyles({
    root: {
      paddingTop: `${theme.spacing(0.5)}px !important`,
      paddingBottom: `${theme.spacing(0.5)}px !important`,
    },
  });

export default Object.assign(withStyles(styles)(ThemeOptionItem), {
  muiName: 'MenuItem',
});
