import { FC, useMemo } from 'react';
import Center from 'raydiant-elements/layout/Center';
import Column from 'raydiant-elements/layout/Column';
import Row from 'raydiant-elements/layout/Row';
import Text from 'raydiant-elements/core/Text';
import Link from 'raydiant-elements/core/Link';
import { createStyles, makeStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import config from '../../config';
import Page from '../../components/Page';

interface PremiumApplicationPageProps {
  applicationId?: string;
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    smallColumn: {
      maxWidth: 570,
    },
    logoImage: {
      width: 48,
      height: 48,
      padding: theme.spacing(0.5),
      objectFit: 'cover',
    },
    errorMessage: {
      fontSize: theme.fontSizes.md,
      fontWeight: 500,
      color: theme.palette.text.primary,
      letterSpacing: 0.2,
    },
    callToAction: {
      fontSize: theme.fontSizes.xl,
      fontWeight: 300,
      lineHeight: 'normal',
      letterSpacing: 0.1,
      color: theme.palette.text.secondary,
    },
  });
});

const ApplicationInvalidPermissions: FC<PremiumApplicationPageProps> = ({
  applicationId,
}) => {
  const classes = useStyles();
  const { icon, appName } = useMemo(() => {
    if (applicationId === config.pmwAppId) {
      return {
        icon: require('../../assets/pmw-icon.svg').default,
        appName: 'PosterMyWall',
      };
    }

    return { icon: '', appName: '' };
  }, [applicationId]);

  return (
    <Page>
      <Center>
        <Column className={classes.smallColumn} doubleMargin>
          <Row center>
            {icon && (
              <img
                alt="disabled icon"
                src={icon}
                className={classes.logoImage}
              />
            )}
            <Text className={classes.errorMessage}>
              You do not seem to have access to
              {appName ? ` Raydiant’s ${appName}` : ' this'} app.
            </Text>
          </Row>
          <Text className={classes.callToAction}>
            Please contact{' '}
            <Link href="mailto:support@raydiant.com" color="inherit">
              support@raydiant.com
            </Link>{' '}
            to enable access
            {appName ? ` to the ${appName} app in Raydiant` : ''}.
          </Text>
        </Column>
      </Center>
    </Page>
  );
};

export default ApplicationInvalidPermissions;
