import isEqual from 'lodash/isEqual';
import { batchActions } from 'redux-batched-actions';
import { delay } from 'redux-saga';
import { all, call, fork, put, select, take } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import * as fileUploadActions from '../actions/fileUploads';
import * as presActions from '../actions/presentations';
import * as folderActions from '../actions/folders';
import {
  selectPresentationsById,
  selectFileStatus,
  PresentationsById,
} from '../selectors/v2/presentations';
import { selectLocalUploads } from '../selectors/fileUploads';
import { selectUserProfile } from '../selectors/user';
import {
  Presentation,
  NewFileUploads,
  Profile,
  CreatePresentation,
} from '@raydiant/api-client-js';
import miraClient from '../clients/miraClient';
import config from '../config';
import logger from '../logger';
import { LocalFileUploads, PresentationFile } from '../types';
import { canDeleteResource } from '../utilities';

type SavePresentationAction = ReturnType<typeof presActions.savePresentation>;
type CreatePresentationAction = ReturnType<
  typeof presActions.createPresentation
>;
type DeletePresentationAction = ReturnType<
  typeof presActions.deleteAllPresentations
>;
type PollPresentationsWithFileUploadsAction = ReturnType<
  typeof presActions.pollPresentationsWithFileUploads
>;
type CopyPresentationAction = ReturnType<typeof presActions.copyPresentation>;

export const fetchPresentations = function* () {
  try {
    yield put(presActions.fetchPresentationsAsync.request());

    const presentations: Presentation[] = yield call(() =>
      miraClient.getPresentations(),
    );
    yield put(presActions.fetchPresentationsAsync.success(presentations));

    return presentations;
  } catch (error: any) {
    logger.error(error);
    yield put(presActions.fetchPresentationsAsync.failure(error));
  }
};

const uploadPresentationFiles = function* (
  pendingFileUploads: PresentationFile[],
  fileUploads: NewFileUploads,
  presentationId: string,
) {
  const uploadFilePayloads = pendingFileUploads.reduce(
    (acc, { path, file, localUrl }) => {
      const fileUploadFields = fileUploads.find((f) =>
        isEqual(['applicationVariables', ...f.path], path),
      );
      return fileUploadFields
        ? [
            ...acc,
            { ...fileUploadFields, file, path, presentationId, localUrl },
          ]
        : acc;
    },
    [] as fileUploadActions.UploadPresentationFileActionProps[],
  );

  yield all(
    uploadFilePayloads.map((payload) =>
      put(fileUploadActions.uploadPresentationFile(payload)),
    ),
  );
};

const createPresentation = function* (
  params: CreatePresentation,
  pendingFileUploads: PresentationFile[] = [],
  folderId?: string | null,
  onSuccess?: (presentation: Presentation) => void,
) {
  try {
    yield put(presActions.createPresentationAsync.request());

    const [presentation, fileUploads]: [Presentation, NewFileUploads] =
      yield call(() => miraClient.createPresentation(params));

    yield call(
      uploadPresentationFiles,
      pendingFileUploads,
      fileUploads,
      presentation.id,
    );

    // NOTE: This needs to happen after uploadPresentationFiles because we depend
    // the upload state being set before the presentation in order to inject any
    // in progress uploads into app vars.
    yield put(presActions.createPresentationAsync.success(presentation));

    if (folderId) {
      yield call(() =>
        miraClient.movePresentationToFolder(presentation.id, folderId),
      );
    }

    yield put(
      folderActions.moveAllItemsToFolder(
        {
          presentationIds: [presentation.id],
          parentFolderId: folderId || null,
        },
        {},
      ),
    );

    if (onSuccess) {
      onSuccess(presentation);
    }
  } catch (error: any) {
    logger.error(error);
    yield put(presActions.createPresentationAsync.failure(error));
  }
};

const updatePresentation = function* (
  params: Presentation,
  pendingFileUploads: PresentationFile[] = [],
  onSave?: (presentation: Presentation) => void,
) {
  try {
    yield put(presActions.updatePresentationAsync.request(params));

    const [presentation, fileUploads]: [Presentation, NewFileUploads] =
      yield call(() => miraClient.updatePresentation(params.id, params));

    yield call(
      uploadPresentationFiles,
      pendingFileUploads,
      fileUploads,
      presentation.id,
    );

    yield put(presActions.updatePresentationAsync.success(presentation));

    if (onSave) {
      onSave(presentation);
    }

    return presentation;
  } catch (error: any) {
    logger.error(error);
    yield put(presActions.updatePresentationAsync.failure(error));
  }
};

const savePresentation = function* (
  params: Presentation | CreatePresentation,
  pendingFileUploads: PresentationFile[] = [],
  folderId?: string,
  onSave?: (presentation: Presentation) => void,
) {
  if ('id' in params && params.id) {
    yield call(updatePresentation, params, pendingFileUploads, onSave);
  } else {
    yield call(
      createPresentation,
      params as CreatePresentation, // need to address the typing for Presentation | CreatePresentation
      pendingFileUploads,
      folderId,
      onSave,
    );
  }
};

const deleteAllPresentations = function* (
  ids: string[],
  onDelete?: () => void,
) {
  try {
    const currentUser: Profile = yield select(selectUserProfile);
    const prentationsById: PresentationsById = yield select(
      selectPresentationsById,
    );

    const deleteableIds = ids.filter((id) => {
      const presentation = prentationsById[id];
      return (
        !!presentation &&
        currentUser &&
        canDeleteResource(currentUser, presentation.resource)
      );
    });

    yield put(
      batchActions(
        deleteableIds.map((id) =>
          presActions.deletePresentationAsync.request(id),
        ),
      ),
    );

    yield all(
      deleteableIds.map((id) => call(() => miraClient.deletePresentation(id))),
    );

    yield put(
      batchActions(
        deleteableIds.map((id) =>
          presActions.deletePresentationAsync.success(id),
        ),
      ),
    );

    if (onDelete) {
      onDelete();
    }
  } catch (error: any) {
    logger.error(error);
    yield put(presActions.deletePresentationAsync.failure(error));
  }
};

const watchDeleteAllPresentations = function* () {
  while (true) {
    const action: DeletePresentationAction = yield take(
      getType(presActions.deleteAllPresentations),
    );
    yield fork(deleteAllPresentations, action.payload);
  }
};

const watchSavePresentation = function* () {
  while (true) {
    const action: SavePresentationAction = yield take(
      getType(presActions.savePresentation),
    );

    yield fork(
      savePresentation,
      action.payload,
      action.meta.fileUploads,
      action.meta.folderId,
      action.meta.onSave,
    );
  }
};

const watchCreatePresentation = function* () {
  while (true) {
    const action: CreatePresentationAction = yield take(
      getType(presActions.createPresentation),
    );

    yield fork(
      createPresentation,
      action.payload,
      action.meta.fileUploads,
      action.meta.folderId,
      action.meta.onSuccess,
    );
  }
};

const watchFetchPresentations = function* () {
  while (true) {
    yield take(getType(presActions.fetchPresentations));
    yield fork(fetchPresentations);
  }
};

const pollingPresentations: { [presentationId: string]: boolean } = {};
const pollPresentations = function* () {
  const presentationIds = Object.keys(pollingPresentations);

  const presentations: Presentation[] = yield call(() =>
    miraClient.getPresentations({ ids: presentationIds }),
  );
  const localUploads: LocalFileUploads = yield select(selectLocalUploads);
  yield put(presActions.fetchPresentationsAsync.success(presentations));

  for (const presentation of presentations) {
    if (!presentation.fileUploads) {
      delete pollingPresentations[presentation.id];
      continue;
    }

    const { isUploading } = selectFileStatus(presentation, localUploads);
    if (!isUploading) {
      delete pollingPresentations[presentation.id];
    }
  }

  if (Object.keys(pollingPresentations).length > 0) {
    yield delay(config.fileUploadPollMS);
    yield call(pollPresentations);
  }
};

const pollPresentationsWithFileUploads = function* (presentations: string[]) {
  const isNotPolling = Object.keys(pollingPresentations).length === 0;
  const presentationsById: PresentationsById = yield select(
    selectPresentationsById,
  );
  const localUploads: LocalFileUploads = yield select(selectLocalUploads);

  // Re-fetch presentations if there are pending file uploads.
  for (const id of presentations) {
    const presentation = presentationsById[id];
    if (!presentation || !presentation.fileUploads) continue;

    const { isUploading } = selectFileStatus(presentation, localUploads);
    if (isUploading) {
      pollingPresentations[id] = true;
    }
  }

  const hasPresentationsToPoll = Object.keys(pollingPresentations).length > 0;

  if (isNotPolling && hasPresentationsToPoll) {
    yield call(pollPresentations);
  }
};

const watchPollPresentationsWithFileUploads = function* () {
  while (true) {
    const action: PollPresentationsWithFileUploadsAction = yield take(
      getType(presActions.pollPresentationsWithFileUploads),
    );
    yield fork(pollPresentationsWithFileUploads, action.payload);
  }
};

const watchStopPollingPresentations = function* () {
  while (true) {
    yield take(getType(presActions.stopPollingPresentations));

    for (const presentationId of Object.keys(pollingPresentations)) {
      delete pollingPresentations[presentationId];
    }
  }
};

const copyPresentation = function* (
  params: { presentationId: string; copyName?: string },
  onSuccess?: (presentation: Presentation) => void,
) {
  try {
    yield put(presActions.copyPresentationAsync.request());

    const presentation: Presentation = yield call(() =>
      miraClient.copyPresentation(params.presentationId, {
        name: params.copyName,
      }),
    );

    yield put(presActions.copyPresentationAsync.success(presentation));

    // Move presentation to it's folder
    yield put(
      presActions.movePresentationToFolderAsync.request({
        presentation,
        parentFolderId: presentation.resource.parentFolderId,
      }),
    );

    if (onSuccess) {
      onSuccess(presentation);
    }
  } catch (error: any) {
    logger.error(error);
    yield put(presActions.copyPresentationAsync.failure(error));
  }
};

const watchCopyPresentation = function* () {
  while (true) {
    const action: CopyPresentationAction = yield take(
      getType(presActions.copyPresentation),
    );
    yield fork(copyPresentation, action.payload, action.meta.onSuccess);
  }
};

export default all([
  fork(watchDeleteAllPresentations),
  fork(watchSavePresentation),
  fork(watchCreatePresentation),
  fork(watchFetchPresentations),
  fork(watchPollPresentationsWithFileUploads),
  fork(watchCopyPresentation),
  fork(watchStopPollingPresentations),
]);
