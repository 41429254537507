import PlaylistPlaySharpIcon from '@material-ui/icons/PlaylistPlaySharp';
import FolderSharpIcon from '@material-ui/icons/FolderSharp';
import { FC } from 'react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import * as P from '../../clients/mira/types/Presentation';
import * as PL from '../../clients/mira/types/Playlist';
import * as F from '../../clients/mira/types/Folder';
import { selectPresentationsById } from '../../selectors/v2/presentations';
import { selectPlaylistsById } from '../../selectors/v2/playlists';
import { selectFoldersById } from '../../selectors/v2/folders';
import TreeView from '../../components/TreeView';
import DragLayer from '../../components/DragLayer';
import { ItemTypes } from '../../components/DragLayer/DragItemTypes';
import PresentationCardThumbnail from '../../components/PresentationCard/PresentationCardThumbnail';
import PlaylistCardThumbnail from '../../components/PlaylistCard/PlaylistCardThumbnail';
import FolderCardThumbnail from '../../components/FolderCard/FolderCardThumbnail';
import { parseNodeId } from '../../utilities';

interface LibraryDragLayerProps {}

const LibraryDragLayer: FC<LibraryDragLayerProps> = () => {
  const presentationsById = useSelector(selectPresentationsById);
  const playlistsById = useSelector(selectPlaylistsById);
  const foldersById = useSelector(selectFoldersById);

  const renderPresentationItem = (
    presentation: P.Presentation,
    itemType: ItemTypes,
  ) => {
    if (itemType === ItemTypes.GridItem) {
      return <PresentationCardThumbnail presentation={presentation} />;
    }

    return (
      <TreeView.DragPreview
        key={presentation.id}
        icon={
          <img
            alt=""
            src={presentation.iconUrl}
            style={{
              width: 19,
              marginRight: 1,
              marginLeft: 1,
              borderRadius: 1,
            }}
          />
        }
        label={presentation.name}
      />
    );
  };

  const renderPlaylistItem = (playlist: PL.Playlist, itemType: ItemTypes) => {
    if (itemType === ItemTypes.GridItem) {
      return <PlaylistCardThumbnail />;
    }

    return (
      <TreeView.DragPreview
        key={playlist.id}
        icon={<PlaylistPlaySharpIcon />}
        label={playlist.name}
      />
    );
  };

  const renderFolderItem = (folder: F.Folder, itemType: ItemTypes) => {
    if (itemType === ItemTypes.GridItem) {
      return <FolderCardThumbnail />;
    }

    return (
      <TreeView.DragPreview
        key={folder.id}
        icon={<FolderSharpIcon />}
        label={folder.name}
      />
    );
  };

  const renderDragItem = (nodeId: string, itemType: ItemTypes) => {
    const { type, id } = parseNodeId(nodeId);
    const presentation = presentationsById[id];
    const playlist = playlistsById[id];
    const folder = foldersById[id];

    let dragItem: React.ReactNode | null = null;
    if (type === 'presentation' && presentation) {
      dragItem = renderPresentationItem(presentation, itemType);
    }
    if (type === 'playlist' && playlist) {
      dragItem = renderPlaylistItem(playlist, itemType);
    }
    if (type === 'folder' && folder) {
      dragItem = renderFolderItem(folder, itemType);
    }

    return dragItem;
  };

  return <DragLayer renderDragItem={renderDragItem} />;
};

export default LibraryDragLayer;
