import { Component } from 'react';
import logger from '../../logger';

export default class ErrorBoundary extends Component {
  componentDidCatch(error: Error) {
    logger.error(error);
  }

  render() {
    return this.props.children;
  }
}
