import { FC, useCallback, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import ActionBar from 'raydiant-elements/core/ActionBar/v2';
import ThemeSelector from 'raydiant-elements/core/ThemeSelector';
import Input from 'raydiant-elements/core/Input';
import Row from 'raydiant-elements/layout/Row';
import { Playlist } from '@raydiant/api-client-js';
import AddIcon from '../../components/AddIcon';
import * as paths from '../../routes/paths';
import usePlaylistPageContext from './usePlaylistPageContext';
import { serializeIDPath } from './usePlaylistPageState/utilities';
import PlaylistItemPlaylistActions from './PlaylistItemPlaylistActions';

interface PlaylistHeaderProps {
  playlist?: Playlist;
}

// Create a "fake" playlist item a playlist so that we can open the modal
// for the top-level playlist.
const createItemFromPlaylist = (playlist: Playlist) => {
  return {
    id: playlist.id,
    playlistId: playlist.id,
    playlist,
    presentationId: null,
  };
};

const PlaylistHeader: FC<PlaylistHeaderProps> = ({ playlist }) => {
  const history = useHistory();
  const {
    queryParams,
    classes,
    getSelection,
    updatePlaylist,
    openMoreActions,
    isMoreActionsOpen,
    getPlaylistNameError,
    getPageUrl,
    getPlaylist,
    openModal,
  } = usePlaylistPageContext();

  // Refs

  const itemRef = useRef<HTMLDivElement | null>(null);

  // State

  const [name, setName] = useState(playlist?.name);

  // Callbacks

  const handleNameBlur = useCallback(() => {
    if (!playlist) return;
    updatePlaylist(playlist.id, { name });
  }, [updatePlaylist, playlist, name]);

  const handleMoreActions = useCallback(() => {
    if (!playlist) return;
    if (!itemRef.current) return;

    openMoreActions(
      { id: '', playlistId: playlist.id, playlist, presentationId: null },
      [],
      itemRef.current,
    );
  }, [playlist, openMoreActions]);

  const selectLibraryItems = useCallback(() => {
    if (!playlist) return;

    const bottomMostSelectedPath = getSelection().pop();

    const pageUrl = getPageUrl({
      ...queryParams,
      addItemsAfterPath: bottomMostSelectedPath
        ? serializeIDPath(bottomMostSelectedPath)
        : '',
    });

    history.push(
      paths.presentations({
        folderId: queryParams.folderId,
        selectionId: 'PlaylistPage',
        backTo: pageUrl,
        addToLabel: playlist.name,
        saveTo: pageUrl,
        sessionId: queryParams.sessionId,
      }),
    );
  }, [playlist, queryParams, history, getSelection, getPageUrl]);

  const showSchedule = useCallback(() => {
    if (!playlist) return;
    openModal(createItemFromPlaylist(playlist), [], 'schedule');
  }, [playlist, openModal]);

  const showRules = useCallback(() => {
    if (!playlist) return;
    openModal(createItemFromPlaylist(playlist), [], 'rules');
  }, [playlist, openModal]);

  const showTags = useCallback(() => {
    if (!playlist) return;
    openModal(createItemFromPlaylist(playlist), [], 'tags');
  }, [playlist, openModal]);

  // Effects

  // Update name when playlist changes.
  useEffect(() => {
    setName(playlist?.name);
  }, [playlist]);

  // Render

  const pagePlaylist = playlist?.id ? getPlaylist(playlist.id) : null;
  const hasSchedule = !!pagePlaylist?.startDatetime;
  const hasRule = !!pagePlaylist?.rule;
  const hasTags = !!pagePlaylist && pagePlaylist.resource.r.tags.length > 0;

  return (
    <ThemeSelector color="light">
      <div className={classes.header}>
        <ActionBar variant="floating" className={classes.playlistActions}>
          <ActionBar.Action
            icon={<AddIcon />}
            variant="vertical"
            label="Add"
            onClick={selectLibraryItems}
          />
        </ActionBar>

        {playlist && (
          <div
            ref={itemRef}
            className={cn(
              classes.playlistItem,
              classes.playlistItemPlaylist,
              classes.playlistItemPlaylistExpanded,
              classes.playlistItemRoot,
            )}
          >
            <Row center>
              <div className={classes.playlistItemIcon}>
                <PlaylistPlayIcon color="inherit" />
              </div>

              <Input
                className={classes.playlistName}
                value={name}
                onChange={setName}
                onBlur={handleNameBlur}
                error={!!getPlaylistNameError(playlist.id)}
              />
            </Row>

            <PlaylistItemPlaylistActions
              itemIdPath={[]}
              moreActionsOpen={isMoreActionsOpen([])}
              onMoreClick={handleMoreActions}
              onScheduleClick={hasSchedule ? showSchedule : undefined}
              onRulesClick={hasRule ? showRules : undefined}
              onTagsClick={hasTags ? showTags : undefined}
            />
          </div>
        )}
      </div>
    </ThemeSelector>
  );
};

export default PlaylistHeader;
