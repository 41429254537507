import Button from 'raydiant-elements/core/Button';
import Center from 'raydiant-elements/layout/Center';
import Heading2 from 'raydiant-elements/typography/Heading2';
import { Component } from 'react';
import { connect } from 'react-redux';
import * as userActions from '../../actions/user';
import * as paths from '../../routes/paths';
import Page from '../../components/Page';
import mapStateToProps from './selectors';

interface EnterpriseWelcomePageProps {
  domainName: string;
}

interface EnterpriseWelcomePageState {}

class EnterpriseWelcomePage extends Component<
  EnterpriseWelcomePageProps,
  EnterpriseWelcomePageState
> {
  state: EnterpriseWelcomePageState = {};

  render() {
    const { domainName } = this.props;
    return (
      <Page hideNavigation>
        <Center>
          <img
            src="https://assets.raydiant.com/raydiant-logo-square-lime-green.svg"
            alt=""
            style={{ width: 60, height: 60 }}
          />
          <br />
          <br />
          <div style={{ maxWidth: 290, textAlign: 'center' }}>
            <Heading2>
              Welcome! You are now part of the {domainName} enterprise domain.
            </Heading2>
            <br />
            <br />
            <Button
              fullWidth
              color="primary"
              label="Go to my Dashboard"
              onClick={() => {
                // This is an unprotected page meaning logged out users can access it.
                // We need to trigger a full page reload in order to fetch the user and their
                // preferences for default home tab. This fixes an issue where a logged out
                // user will not be redirected after accepting a domain invite.
                window.location.href = paths.home();
              }}
            />
          </div>
        </Center>
      </Page>
    );
  }
}

export default connect(mapStateToProps, { ...userActions })(
  EnterpriseWelcomePage,
);
