import { JSONObject, DeviceAIOption } from '@raydiant/api-client-js';
import { FlatDeviceAIOption, OptionVal } from './deviceAiSettings.interface';

const valueFromPath = (settings: any, path: string): OptionVal | undefined => {
  const pth = path.split('/');

  if (pth.length === 2 && pth[1] in settings) {
    return settings[pth[1]];
  }

  if (pth.length === 3 && pth[1] in settings) {
    return settings[pth[1]][pth[2]];
  }
};

const guid = () => {
  var result, i, j;
  result = '';
  for (j = 0; j < 32; j++) {
    if (j === 8 || j === 12 || j === 16 || j === 20) result = result + '-';
    i = Math.floor(Math.random() * 16)
      .toString(16)
      .toUpperCase();
    result = result + i;
  }
  return result;
};

const flattenSettings = (
  schema: Array<FlatDeviceAIOption>,
  settings: JSONObject,
): Record<string, OptionVal> => {
  const res = {} as Record<string, OptionVal>;
  schema.forEach((elem) => {
    const k = elem.$id as string;
    const v = valueFromPath(settings, k) as OptionVal;
    res[k] = v;
  });
  return res;
};

const unflattenSettings = (settings: Record<string, OptionVal>): JSONObject => {
  var res = {} as JSONObject;
  Object.entries(settings).forEach((ent) => {
    var path = ent[0].split('/').slice(1);
    var tgt = res;
    while (path.length - 1) {
      const el = path.shift();
      if (el !== undefined) {
        if (!(el in tgt)) {
          tgt[el] = {} as JSONObject;
        }
        tgt = tgt[el] as JSONObject;
      }
    }
    tgt[path[0]] = ent[1];
  });
  return res;
};

const flattenSchema = (
  schema: Record<string, DeviceAIOption>,
): Array<FlatDeviceAIOption> => {
  const _flat = (memo: Array<FlatDeviceAIOption>, elem: DeviceAIOption) => {
    if (elem.properties === undefined) {
      return [...memo, elem];
    }
    return memo.concat(
      flattenSchema(elem.properties) as Array<FlatDeviceAIOption>,
    );
  };
  const res = Object.values(schema).reduce(
    _flat,
    [],
  ) as Array<FlatDeviceAIOption>;
  return res;
};

export {
  flattenSchema,
  flattenSettings,
  guid,
  unflattenSettings,
  valueFromPath,
};
