export interface FontFamily {
  id: string;
  name: string;
  default: string;
  fonts: Font[];
}

export interface Font {
  name: string;
  url: string;
}

export const fontFamilies: FontFamily[] = [
  {
    id: 'abril-fatface',
    name: 'Abril Fatface',
    default: 'Regular',
    fonts: [
      {
        name: 'Regular',
        url: 'https://fonts.raydiant.com/AbrilFatface-Regular.woff',
      },
    ],
  },
  {
    id: 'alice',
    name: 'Alice',
    default: 'Regular',
    fonts: [
      {
        name: 'Regular',
        url: 'https://fonts.raydiant.com/Alice-Regular.woff',
      },
    ],
  },
  {
    id: 'arimo',
    name: 'Arimo',
    default: 'Regular',
    fonts: [
      {
        name: 'Regular',
        url: 'https://fonts.raydiant.com/Arimo-Regular.woff',
      },
      {
        name: 'Medium',
        url: 'https://fonts.raydiant.com/Arimo-Medium.woff',
      },
      {
        name: 'Semi Bold',
        url: 'https://fonts.raydiant.com/Arimo-SemiBold.woff',
      },
      {
        name: 'Bold',
        url: 'https://fonts.raydiant.com/Arimo-Bold.woff',
      },
      {
        name: 'Italic',
        url: 'https://fonts.raydiant.com/Arimo-Italic.woff',
      },
      {
        name: 'Medium Italic',
        url: 'https://fonts.raydiant.com/Arimo-MediumItalic.woff',
      },
      {
        name: 'Semi Bold Italic',
        url: 'https://fonts.raydiant.com/Arimo-SemiBoldItalic.woff',
      },
      {
        name: 'Bold Italic',
        url: 'https://fonts.raydiant.com/Arimo-BoldItalic.woff',
      },
    ],
  },
  {
    id: 'cabin',
    name: 'Cabin',
    default: 'Regular',
    fonts: [
      {
        name: 'Regular',
        url: 'https://fonts.raydiant.com/Cabin-Regular.woff',
      },
      {
        name: 'Medium',
        url: 'https://fonts.raydiant.com/Cabin-Medium.woff',
      },
      {
        name: 'Semi Bold',
        url: 'https://fonts.raydiant.com/Cabin-SemiBold.woff',
      },
      {
        name: 'Bold',
        url: 'https://fonts.raydiant.com/Cabin-Bold.woff',
      },
      {
        name: 'Italic',
        url: 'https://fonts.raydiant.com/Cabin-Italic.woff',
      },
      {
        name: 'Medium Italic',
        url: 'https://fonts.raydiant.com/Cabin-MediumItalic.woff',
      },
      {
        name: 'Semi Bold Italic',
        url: 'https://fonts.raydiant.com/Cabin-SemiBoldItalic.woff',
      },
      {
        name: 'Bold Italic',
        url: 'https://fonts.raydiant.com/Cabin-BoldItalic.woff',
      },
    ],
  },
  {
    id: 'kanit',
    name: 'Kanit',
    default: 'Regular',
    fonts: [
      {
        name: 'Thin',
        url: 'https://fonts.raydiant.com/Kanit-Thin.woff',
      },
      {
        name: 'Extra Light',
        url: 'https://fonts.raydiant.com/Kanit-ExtraLight.woff',
      },
      {
        name: 'Light',
        url: 'https://fonts.raydiant.com/Kanit-Light.woff',
      },
      {
        name: 'Regular',
        url: 'https://fonts.raydiant.com/Kanit-Regular.woff',
      },
      {
        name: 'Medium',
        url: 'https://fonts.raydiant.com/Kanit-Medium.woff',
      },
      {
        name: 'Semi Bold',
        url: 'https://fonts.raydiant.com/Kanit-SemiBold.woff',
      },
      {
        name: 'Bold',
        url: 'https://fonts.raydiant.com/Kanit-Bold.woff',
      },
      {
        name: 'Extra Bold',
        url: 'https://fonts.raydiant.com/Kanit-ExtraBold.woff',
      },
      {
        name: 'Black',
        url: 'https://fonts.raydiant.com/Kanit-Black.woff',
      },
      {
        name: 'Thin Italic',
        url: 'https://fonts.raydiant.com/Kanit-ThinItalic.woff',
      },
      {
        name: 'Extra Light Italic',
        url: 'https://fonts.raydiant.com/Kanit-ExtraLightItalic.woff',
      },
      {
        name: 'Light Italic',
        url: 'https://fonts.raydiant.com/Kanit-LightItalic.woff',
      },
      {
        name: 'Italic',
        url: 'https://fonts.raydiant.com/Kanit-Italic.woff',
      },
      {
        name: 'Medium Italic',
        url: 'https://fonts.raydiant.com/Kanit-MediumItalic.woff',
      },
      {
        name: 'Semi Bold Italic',
        url: 'https://fonts.raydiant.com/Kanit-SemiBoldItalic.woff',
      },
      {
        name: 'Bold Italic',
        url: 'https://fonts.raydiant.com/Kanit-BoldItalic.woff',
      },
      {
        name: 'Extra Bold Italic',
        url: 'https://fonts.raydiant.com/Kanit-ExtraBoldItalic.woff',
      },
      {
        name: 'Black Italic',
        url: 'https://fonts.raydiant.com/Kanit-BlackItalic.woff',
      },
    ],
  },
  {
    id: 'lobster',
    name: 'Lobster',
    default: 'Regular',
    fonts: [
      {
        name: 'Regular',
        url: 'https://fonts.raydiant.com/Lobster-Regular.woff',
      },
    ],
  },
  // TODO: This should only show for accounts in the Oracle domain.
  // {
  //   name: 'Oracle Sans',
  //   fonts: [
  //     {
  //       name: 'Light',
  //       url: 'https://fonts.raydiant.com/oracle/OracleSans_Lt.woff',
  //     },
  //     {
  //       name: 'Bold',
  //       url: 'https://fonts.raydiant.com/oracle/OracleSans_Bd.woff',
  //     },
  //   ],
  // },
  {
    id: 'oswald',
    name: 'Oswald',
    default: 'Regular',
    fonts: [
      {
        name: 'Extra Light',
        url: 'https://fonts.raydiant.com/Oswald-ExtraLight.woff',
      },
      {
        name: 'Light',
        url: 'https://fonts.raydiant.com/Oswald-Light.woff',
      },
      {
        name: 'Regular',
        url: 'https://fonts.raydiant.com/Oswald-Regular.woff',
      },
      {
        name: 'Medium',
        url: 'https://fonts.raydiant.com/Oswald-Medium.woff',
      },
      {
        name: 'Semi Bold',
        url: 'https://fonts.raydiant.com/Oswald-SemiBold.woff',
      },
      {
        name: 'Bold',
        url: 'https://fonts.raydiant.com/Oswald-Bold.woff',
      },
    ],
  },
  {
    id: 'pacifico',
    name: 'Pacifico',
    default: 'Regular',
    fonts: [
      {
        name: 'Regular',
        url: 'https://fonts.raydiant.com/Pacifico-Regular.woff',
      },
    ],
  },
  {
    id: 'permanent-marker',
    name: 'Permanent Marker',
    default: 'Regular',
    fonts: [
      {
        name: 'Regular',
        url: 'https://fonts.raydiant.com/PermanentMarker-Regular.woff',
      },
    ],
  },
  {
    id: 'playfair-display',
    name: 'Playfair Display',
    default: 'Regular',
    fonts: [
      {
        name: 'Regular',
        url: 'https://fonts.raydiant.com/PlayfairDisplay-Regular.woff',
      },
      {
        name: 'Medium',
        url: 'https://fonts.raydiant.com/PlayfairDisplay-Medium.woff',
      },
      {
        name: 'Semi Bold',
        url: 'https://fonts.raydiant.com/PlayfairDisplay-SemiBold.woff',
      },
      {
        name: 'Bold',
        url: 'https://fonts.raydiant.com/PlayfairDisplay-Bold.woff',
      },
      {
        name: 'Extra Bold',
        url: 'https://fonts.raydiant.com/PlayfairDisplay-ExtraBold.woff',
      },
      {
        name: 'Black',
        url: 'https://fonts.raydiant.com/PlayfairDisplay-Black.woff',
      },
      {
        name: 'Italic',
        url: 'https://fonts.raydiant.com/PlayfairDisplay-Italic.woff',
      },
      {
        name: 'Medium Italic',
        url: 'https://fonts.raydiant.com/PlayfairDisplay-MediumItalic.woff',
      },
      {
        name: 'Semi Bold Italic',
        url: 'https://fonts.raydiant.com/PlayfairDisplay-SemiBoldItalic.woff',
      },
      {
        name: 'Bold Italic',
        url: 'https://fonts.raydiant.com/PlayfairDisplay-BoldItalic.woff',
      },
      {
        name: 'Extra Bold Italic',
        url: 'https://fonts.raydiant.com/PlayfairDisplay-ExtraBoldItalic.woff',
      },
      {
        name: 'Black Italic',
        url: 'https://fonts.raydiant.com/PlayfairDisplay-BlackItalic.woff',
      },
    ],
  },
  {
    id: 'poppins',
    name: 'Poppins',
    default: 'Regular',
    fonts: [
      {
        name: 'Thin',
        url: 'https://fonts.raydiant.com/Poppins-Thin.woff',
      },
      {
        name: 'Extra Light',
        url: 'https://fonts.raydiant.com/Poppins-ExtraLight.woff',
      },
      {
        name: 'Light',
        url: 'https://fonts.raydiant.com/Poppins-Light.woff',
      },
      {
        name: 'Regular',
        url: 'https://fonts.raydiant.com/Poppins-Regular.woff',
      },
      {
        name: 'Medium',
        url: 'https://fonts.raydiant.com/Poppins-Medium.woff',
      },
      {
        name: 'Semi Bold',
        url: 'https://fonts.raydiant.com/Poppins-SemiBold.woff',
      },
      {
        name: 'Bold',
        url: 'https://fonts.raydiant.com/Poppins-Bold.woff',
      },
      {
        name: 'Extra Bold',
        url: 'https://fonts.raydiant.com/Poppins-ExtraBold.woff',
      },
      {
        name: 'Black',
        url: 'https://fonts.raydiant.com/Poppins-Black.woff',
      },
      {
        name: 'Thin Italic',
        url: 'https://fonts.raydiant.com/Poppins-ThinItalic.woff',
      },
      {
        name: 'Extra Light Italic',
        url: 'https://fonts.raydiant.com/Poppins-ExtraLightItalic.woff',
      },
      {
        name: 'Light Italic',
        url: 'https://fonts.raydiant.com/Poppins-LightItalic.woff',
      },
      {
        name: 'Italic',
        url: 'https://fonts.raydiant.com/Poppins-Italic.woff',
      },
      {
        name: 'Medium Italic',
        url: 'https://fonts.raydiant.com/Poppins-MediumItalic.woff',
      },
      {
        name: 'Semi Bold Italic',
        url: 'https://fonts.raydiant.com/Poppins-SemiBoldItalic.woff',
      },
      {
        name: 'Bold Italic',
        url: 'https://fonts.raydiant.com/Poppins-BoldItalic.woff',
      },
      {
        name: 'Extra Bold Italic',
        url: 'https://fonts.raydiant.com/Poppins-ExtraBoldItalic.woff',
      },
      {
        name: 'Black Italic',
        url: 'https://fonts.raydiant.com/Poppins-BlackItalic.woff',
      },
    ],
  },
  {
    id: 'raleway',
    name: 'Raleway',
    default: 'Regular',
    fonts: [
      {
        name: 'Thin',
        url: 'https://fonts.raydiant.com/Raleway-Thin.woff',
      },
      {
        name: 'Extra Light',
        url: 'https://fonts.raydiant.com/Raleway-ExtraLight.woff',
      },
      {
        name: 'Light',
        url: 'https://fonts.raydiant.com/Raleway-Light.woff',
      },
      {
        name: 'Regular',
        url: 'https://fonts.raydiant.com/Raleway-Regular.woff',
      },
      {
        name: 'Medium',
        url: 'https://fonts.raydiant.com/Raleway-Medium.woff',
      },
      {
        name: 'Semi Bold',
        url: 'https://fonts.raydiant.com/Raleway-SemiBold.woff',
      },
      {
        name: 'Bold',
        url: 'https://fonts.raydiant.com/Raleway-Bold.woff',
      },
      {
        name: 'Extra Bold',
        url: 'https://fonts.raydiant.com/Raleway-ExtraBold.woff',
      },
      {
        name: 'Black',
        url: 'https://fonts.raydiant.com/Raleway-Black.woff',
      },
      {
        name: 'Thin Italic',
        url: 'https://fonts.raydiant.com/Raleway-ThinItalic.woff',
      },
      {
        name: 'Extra Light Italic',
        url: 'https://fonts.raydiant.com/Raleway-ExtraLightItalic.woff',
      },
      {
        name: 'Light Italic',
        url: 'https://fonts.raydiant.com/Raleway-LightItalic.woff',
      },
      {
        name: 'Italic',
        url: 'https://fonts.raydiant.com/Raleway-Italic.woff',
      },
      {
        name: 'Medium Italic',
        url: 'https://fonts.raydiant.com/Raleway-MediumItalic.woff',
      },
      {
        name: 'Semi Bold Italic',
        url: 'https://fonts.raydiant.com/Raleway-SemiBoldItalic.woff',
      },
      {
        name: 'Bold Italic',
        url: 'https://fonts.raydiant.com/Raleway-BoldItalic.woff',
      },
      {
        name: 'Extra Bold Italic',
        url: 'https://fonts.raydiant.com/Raleway-ExtraBoldItalic.woff',
      },
      {
        name: 'Black Italic',
        url: 'https://fonts.raydiant.com/Raleway-BlackItalic.woff',
      },
    ],
  },
  {
    id: 'righteous',
    name: 'Righteous',
    default: 'Regular',
    fonts: [
      {
        name: 'Regular',
        url: 'https://fonts.raydiant.com/Righteous-Regular.woff',
      },
    ],
  },
  {
    id: 'roboto',
    name: 'Roboto',
    default: 'Regular',
    fonts: [
      {
        name: 'Thin',
        url: 'https://fonts.raydiant.com/Roboto-Thin.woff',
      },
      {
        name: 'Light',
        url: 'https://fonts.raydiant.com/Roboto-Light.woff',
      },
      {
        name: 'Regular',
        url: 'https://fonts.raydiant.com/Roboto-Regular.woff',
      },
      {
        name: 'Medium',
        url: 'https://fonts.raydiant.com/Roboto-Medium.woff',
      },
      {
        name: 'Bold',
        url: 'https://fonts.raydiant.com/Roboto-Bold.woff',
      },
      {
        name: 'Black',
        url: 'https://fonts.raydiant.com/Roboto-Black.woff',
      },
      {
        name: 'Thin Italic',
        url: 'https://fonts.raydiant.com/Roboto-ThinItalic.woff',
      },
      {
        name: 'Light Italic',
        url: 'https://fonts.raydiant.com/Roboto-LightItalic.woff',
      },
      {
        name: 'Medium Italic',
        url: 'https://fonts.raydiant.com/Roboto-MediumItalic.woff',
      },
      {
        name: 'Bold Italic',
        url: 'https://fonts.raydiant.com/Roboto-BoldItalic.woff',
      },
      {
        name: 'Black Italic',
        url: 'https://fonts.raydiant.com/Roboto-BlackItalic.woff',
      },
    ],
  },
  {
    id: 'shadows-into-light-two',
    name: 'Shadows Into Light Two',
    default: 'Regular',
    fonts: [
      {
        name: 'Regular',
        url: 'https://fonts.raydiant.com/ShadowsIntoLightTwo-Regular.woff',
      },
    ],
  },
];

export const getFontFamilyForUrl = (
  fontFamilies: FontFamily[],
  fontUrl: string,
): FontFamily | null => {
  for (const fontFamily of fontFamilies) {
    for (const font of fontFamily.fonts) {
      if (font.url === fontUrl) {
        return fontFamily;
      }
    }
  }
  return null;
};

export const getFontForUrl = (
  fontFamilies: FontFamily[],
  fontUrl: string,
): Font | null => {
  for (const fontFamily of fontFamilies) {
    for (const font of fontFamily.fonts) {
      if (font.url === fontUrl) {
        return font;
      }
    }
  }
  return null;
};

export const getFontUrl = (fontFamily: FontFamily, fontName: string) => {
  const defaultFont = fontFamily.fonts.find((font) => font.name === fontName);

  return defaultFont?.url || '';
};

export const getDefaultFontUrl = (fontFamily: FontFamily) => {
  return getFontUrl(fontFamily, fontFamily.default);
};

export const injectFontFaces = (fontFamilies: FontFamily[]) => {
  let styleContents = '';

  fontFamilies.forEach((fontFamily) => {
    styleContents += `
@font-face {
  font-family: ${fontFamily.id};
  src: url(${getDefaultFontUrl(fontFamily)}) format('woff');
  font-display: fallback;
}`;
  });

  const style = document.createElement('style');
  style.setAttribute('data-font-families', '');
  style.textContent = styleContents;

  document.head.appendChild(style);
};
