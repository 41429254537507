import { useRef } from 'react';
import cn from 'classnames';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import { Theme } from '@raydiant/api-client-js';
import Checkbox from 'raydiant-elements/core/Checkbox';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme as RETheme } from 'raydiant-elements/theme';
import ThemeItem from './ThemeItem';

interface ThemeManagerItemProps {
  theme: Theme;
  selectable?: boolean;
  actionable?: boolean;
  selected?: boolean;
  onSelect?: (selected: boolean) => void;
  onMore?: (el: HTMLButtonElement) => void;
}

const ThemeManagerItem = ({
  theme,
  selectable,
  actionable,
  selected = false,
  onSelect,
  onMore,
}: ThemeManagerItemProps) => {
  const classes = useStyles();

  const moreActionsRef = useRef<HTMLButtonElement | null>(null);

  const themeItem = (
    <ThemeItem theme={theme} className={cn(!selectable && classes.indent)} />
  );

  return (
    <div className={cn(classes.item)}>
      {selectable ? (
        <Checkbox checked={selected} onChange={onSelect} label={themeItem} />
      ) : (
        themeItem
      )}
      {actionable && (
        <IconButton
          edge="end"
          size="small"
          className={classes.moreActions}
          ref={moreActionsRef}
          onClick={() => {
            if (onMore && moreActionsRef.current) {
              onMore(moreActionsRef.current);
            }
          }}
        >
          <MoreHorizIcon color="inherit" />
        </IconButton>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: RETheme) => {
  return createStyles({
    item: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: theme.spacing(0.5),

      '&:last-child': {
        marginBottom: 0,
      },
    },

    moreActions: {
      opacity: 0.6,
    },

    indent: {
      paddingLeft: 26, // Line up with other swatches when there's a checkbox.
    },
  });
});

export default ThemeManagerItem;
