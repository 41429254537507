import { all, call, fork, put, take } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import {
  PlaybackReport,
  GeneratePlaybackReportInput,
} from '@raydiant/api-client-js';
import * as playbackReportActions from '../actions/playbackReports';
import miraClient from '../clients/miraClient';
import logger from '../logger';

type GeneratePlaybackReportAction = ReturnType<
  typeof playbackReportActions.generatePlaybackReport
>;

const fetchPlaybackReports = function* () {
  try {
    yield put(playbackReportActions.fetchPlaybackReportsAsync.request());
    const playbackReports: PlaybackReport[] = yield call(() =>
      miraClient.getPlaybackReports(),
    );
    yield put(
      playbackReportActions.fetchPlaybackReportsAsync.success(playbackReports),
    );
    return playbackReports;
  } catch (error: any) {
    logger.error(error);
    yield put(playbackReportActions.fetchPlaybackReportsAsync.failure(error));
  }
};

const watchFetchPlaybackReports = function* () {
  while (true) {
    yield take(getType(playbackReportActions.fetchPlaybackReports));
    yield call(fetchPlaybackReports);
  }
};

const generatePlaybackReport = function* (
  input: GeneratePlaybackReportInput,
  onSuccess: (playbackReport: PlaybackReport) => void,
) {
  try {
    yield put(playbackReportActions.generatePlaybackReportAsync.request());
    const playbackReport: PlaybackReport = yield call(() =>
      miraClient.generatePlaybackReport(input),
    );
    yield put(
      playbackReportActions.generatePlaybackReportAsync.success(playbackReport),
    );

    if (onSuccess) {
      onSuccess(playbackReport);
    }
    return playbackReport;
  } catch (error: any) {
    logger.error(error);
    yield put(playbackReportActions.generatePlaybackReportAsync.failure(error));
  }
};

const watchGeneratePlaybackReport = function* () {
  while (true) {
    const action: GeneratePlaybackReportAction = yield take(
      getType(playbackReportActions.generatePlaybackReport),
    );
    yield call(generatePlaybackReport, action.payload, action.meta?.onSuccess);
  }
};

export default all([
  fork(watchFetchPlaybackReports),
  fork(watchGeneratePlaybackReport),
]);
