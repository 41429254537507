import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import * as deviceActions from '../../actions/devices';
import PlaylistSelector from '../../components/PlaylistSelector';
import * as D from '../../clients/mira/types/Device';

interface DevicePlaylistSelectorProps {
  device: D.Device;
  selectedProfileId: string;
  onSelectProfile: (profileId: string) => void;
  onNewPlaylist: () => void;
  onNewPresentation: (applicationId: string) => void;
  onClose: () => void;
}

const DevicePlaylistSelector: FC<DevicePlaylistSelectorProps> = ({
  device,
  selectedProfileId,
  onSelectProfile,
  onNewPlaylist,
  onNewPresentation,
  onClose,
}) => {
  const dispatch = useDispatch();

  // Callbacks

  const updateDevice = useCallback(
    (updatedDevice: Partial<D.Device>, onUpdate?: () => void) => {
      if (!device) return;
      dispatch(
        deviceActions.updateDevice(
          {
            ...device,
            ...updatedDevice,
          },
          { onUpdate },
        ),
      );

      onClose();
    },
    [dispatch, device, onClose],
  );

  const updateDevicePlaylist = useCallback(
    (playlistId: string) => {
      if (!device) return;
      if (device.playlistId === playlistId) return;
      updateDevice({ playlistId });
    },
    [updateDevice, device],
  );

  // Render

  return (
    <PlaylistSelector
      selectedProfileId={selectedProfileId}
      onSelectProfile={onSelectProfile}
      onSelectPlaylist={updateDevicePlaylist}
      onNewPlaylist={onNewPlaylist}
      onNewPresentation={onNewPresentation}
    />
  );
};

export default DevicePlaylistSelector;
