import { FC } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const OpenBracketIcon: FC = () => {
  return (
    <SvgIcon viewBox="0 0 24 24">
      <path d="M14.27 21.578v-1.813c-.506-.014-.89-.348-1.15-1.005-.26-.656-.429-1.54-.507-2.652-.078-1.111-.117-2.538-.117-4.28 0-1.755.039-3.188.117-4.3.078-1.111.244-2.005.497-2.681.254-.676.64-1.014 1.16-1.014V2c-1.26 0-2.2.439-2.817 1.316-.618.878-1.014 1.993-1.19 3.345C10.088 8.011 10 9.735 10 11.828c0 2.028.088 3.718.263 5.07.176 1.352.575 2.47 1.2 3.354.623.884 1.56 1.326 2.808 1.326z" />
    </SvgIcon>
  );
};

export default OpenBracketIcon;
