import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import Button from 'raydiant-elements/core/Button';

export interface FontUploadDialogProps {
  open: boolean;
  onClose: () => void;
}

const FontUploadDialog = ({ open, onClose }: FontUploadDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <DialogContentText>
          Your fonts have successfully uploaded and are now processing. It may
          take up to a minute before they become visible.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FontUploadDialog;
