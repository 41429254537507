import { all, call, fork, put, take } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import * as fileUploadActions from '../actions/fileUploads';
import miraClient from '../clients/miraClient';
import logger from '../logger';

type UploadFileAction = ReturnType<typeof fileUploadActions.uploadFile>;
type UploadPresentationFileAction = ReturnType<
  typeof fileUploadActions.uploadPresentationFile
>;

const uploadPresentationFile = function* (
  fileUploadId: string,
  uploadUrl: string,
  file: File,
  localUrl: string,
  presentationId: string,
  path: Array<string | number>,
) {
  try {
    yield put(
      fileUploadActions.uploadPresentationFileAsync.request({
        fileUploadId,
        localUrl,
        presentationId,
        path,
      }),
    );
    yield call(() => miraClient.uploadFile(fileUploadId, uploadUrl, file));
    yield put(fileUploadActions.uploadFileAsync.success({ fileUploadId }));
  } catch (error: any) {
    logger.error(error);
    yield put(
      fileUploadActions.uploadFileAsync.failure({
        fileUploadId,
        error,
      }),
    );
  }
};

const uploadFile = function* (
  fileUploadId: string,
  uploadUrl: string,
  file: File,
  localUrl: string,
) {
  try {
    yield put(
      fileUploadActions.uploadFileAsync.request({
        fileUploadId,
        localUrl,
      }),
    );
    yield call(() => miraClient.uploadFile(fileUploadId, uploadUrl, file));
    yield put(fileUploadActions.uploadFileAsync.success({ fileUploadId }));
  } catch (error: any) {
    logger.error(error);
    yield put(
      fileUploadActions.uploadFileAsync.failure({
        fileUploadId,
        error,
      }),
    );
  }
};

const watchUploadPresentationFile = function* () {
  while (true) {
    const action: UploadPresentationFileAction = yield take(
      getType(fileUploadActions.uploadPresentationFile),
    );
    const { fileUploadId, uploadUrl, file, localUrl, presentationId, path } =
      action.payload;

    yield fork(
      uploadPresentationFile,
      fileUploadId,
      uploadUrl,
      file,
      localUrl,
      presentationId,
      path,
    );
  }
};

const watchUploadFile = function* () {
  while (true) {
    const action: UploadFileAction = yield take(
      getType(fileUploadActions.uploadFile),
    );
    const { fileUploadId, uploadUrl, file, localUrl } = action.payload;

    yield fork(uploadFile, fileUploadId, uploadUrl, file, localUrl);
  }
};

export default all([fork(watchUploadFile), fork(watchUploadPresentationFile)]);
