import { useCallback, useMemo, useRef, useState } from 'react';
import ColorPicker from 'raydiant-elements/core/ColorPicker';
import InputLabel from 'raydiant-elements/core/InputLabel';
import Select from 'raydiant-elements/core/Select';
import Popover from '@material-ui/core/Popover';
import Row from 'raydiant-elements/layout/Row';
import Spacer from 'raydiant-elements/layout/Spacer';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import { buttonReset } from 'raydiant-elements/mixins';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import FontFamilyPreview from './FontFamilyPreview';
import {
  FontFamily,
  getDefaultFontUrl,
  getFontFamilyForUrl,
} from './fontFamilies';

export interface TextStyleFieldProps {
  color: string;
  fonts: FontFamily[];
  fontUrl: string;
  label: string;
  onFontChange: (font: string) => void;
  onColorChange: (color: string) => void;
}

const TextStyleField = ({
  color,
  fonts,
  fontUrl,
  label,
  onFontChange,
  onColorChange,
}: TextStyleFieldProps) => {
  const classes = useStyles();

  // State

  const [isFontSelectorOpen, setIsFontSelectorOpen] = useState(false);
  const [isColorSelectorOpen, setIsColorSelectorOpen] = useState(false);

  // Refs

  const fontSelectorRef = useRef<HTMLButtonElement | null>(null);
  const colorSelectorRef = useRef<HTMLButtonElement | null>(null);

  // Memoizers

  const selectedFontFamily = useMemo(() => {
    return getFontFamilyForUrl(fonts, fontUrl);
  }, [fontUrl, fonts]);

  // Callbacks

  const selectFont = useCallback(
    (fontUrl: string) => {
      onFontChange(fontUrl);
      setIsFontSelectorOpen(false);
    },
    [onFontChange],
  );

  const selectDefaultFont = useCallback(
    (fontFamilyId: string) => {
      const fontFamily = fonts.find(
        (fontFamily) => fontFamily.id === fontFamilyId,
      );
      if (!fontFamily) return;

      const defaultFontUrl = getDefaultFontUrl(fontFamily);
      if (!defaultFontUrl) return;

      selectFont(defaultFontUrl);
    },
    [selectFont, fonts],
  );

  // Render

  const disableFontSelector =
    !selectedFontFamily || selectedFontFamily.fonts.length <= 1;

  return (
    <div className={classes.root}>
      <InputLabel>{label}</InputLabel>
      <div className={classes.input}>
        <Select
          native={false}
          className={classes.fontFamily}
          classes={{ select: classes.fontFamilySelect }}
          value={selectedFontFamily?.id || ''}
          onChange={selectDefaultFont}
        >
          {fonts.map((fontFamily) => (
            <MenuItem key={fontFamily.id} value={fontFamily.id}>
              <FontFamilyPreview fontFamily={fontFamily} />
            </MenuItem>
          ))}
        </Select>
        <Row className={classes.actions} halfMargin center>
          <IconButton
            ref={fontSelectorRef}
            size="small"
            disabled={disableFontSelector}
            onClick={() => setIsFontSelectorOpen(true)}
          >
            <MoreHorizIcon color="inherit" />
          </IconButton>

          <Spacer />

          <button
            ref={colorSelectorRef}
            className={classes.color}
            style={{ backgroundColor: color }}
            onClick={() => setIsColorSelectorOpen(true)}
          />
        </Row>
      </div>

      <Menu
        anchorEl={fontSelectorRef.current}
        open={isFontSelectorOpen}
        onClose={() => setIsFontSelectorOpen(false)}
      >
        {selectedFontFamily?.fonts.map((font, index) => (
          <MenuItem
            key={index}
            selected={fontUrl === font.url}
            onClick={() => selectFont(font.url)}
          >
            {font.name}
          </MenuItem>
        ))}
      </Menu>

      <Popover
        anchorEl={colorSelectorRef.current}
        open={isColorSelectorOpen}
        onClose={() => setIsColorSelectorOpen(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ColorPicker
          value={color}
          onChange={onColorChange}
          onClose={() => setIsColorSelectorOpen(false)}
        />
      </Popover>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {},

    input: {
      color: theme.input.foreground,
      backgroundColor: theme.palette.background.default,
      border: `1px solid ${theme.input.border}`,
      borderRadius: theme.borderRadius.sm,
    },

    fontFamily: {
      display: 'flex',
      height: '100%',
    },

    fontFamilySelect: {
      padding: theme.spacing(3, 2),
      border: 'none',
    },

    actions: {
      borderTop: `1px solid ${theme.input.border}`,
      padding: theme.spacing(0.5, 1),
    },

    color: {
      ...buttonReset(),
      position: 'relative',
      cursor: 'pointer',
      width: 22,
      height: 22,
      borderRadius: 100,
      boxShadow: theme.shadows[1],

      '&:after': {
        content: '" "',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: 100,
        boxShadow: theme.shadows[3],
        opacity: 0,
        transition: 'opacity 0.2s ease-in-out',
        pointerEvents: 'none',
      },

      '&:hover:after': {
        opacity: 0.5,
      },

      '&:active:after': {
        opacity: 0,
      },
    },
  });
});

export default TextStyleField;
