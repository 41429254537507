import { useCallback, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from 'raydiant-elements/core/Button';
import TextField from 'raydiant-elements/core/TextField';
import AlertIcon from 'raydiant-elements/core/AlertIcon';
import Text from 'raydiant-elements/core/Text';
import Form from 'raydiant-elements/core/Form';
import Row from 'raydiant-elements/layout/Row';
import Spacer from 'raydiant-elements/layout/Spacer';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';

interface UnregisterDeviceConfirmationProps {
  open: boolean;
  onConfirm: ((result: boolean) => void) | null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // Use a descendant selector to override MUI's default maxWidth
      '& $paper': {
        maxWidth: 280,
      },
    },
    paper: {},

    content: {
      padding: theme.spacing(2),
    },

    actions: {
      paddingTop: 0,
      padding: theme.spacing(2),
    },
  }),
);

const UnregisterDeviceConfirmation = ({
  open,
  onConfirm,
}: UnregisterDeviceConfirmationProps) => {
  const classes = useStyles();

  const [inputValue, setInputValue] = useState<string | null>(null);

  const handleCancel = useCallback(() => {
    if (!onConfirm) return;
    onConfirm(false);
  }, [onConfirm]);

  const handleOk = useCallback(() => {
    if (!onConfirm) return;
    onConfirm(true);
  }, [onConfirm]);

  const isRemoveDisabled = (inputValue ?? '').toLowerCase() !== 'remove';

  return (
    <Dialog open={open} classes={{ root: classes.root, paper: classes.paper }}>
      <Form onSubmit={handleOk}>
        <div className={classes.content}>
          <Row halfMargin>
            <AlertIcon color="warning" />
            <Text>
              Removing a screen cannot be undone. To proceed, type{' '}
              <strong>remove</strong> below:
            </Text>
          </Row>
          <TextField
            autoFocus
            label=""
            value={inputValue ?? ''}
            onChange={setInputValue}
          />
        </div>
        <Row className={classes.actions}>
          <Spacer />
          <Button label="Cancel" onClick={handleCancel} />
          <Button
            type="submit"
            label="Remove"
            color="destructive"
            disabled={isRemoveDisabled}
          />
        </Row>
      </Form>
    </Dialog>
  );
};

export default UnregisterDeviceConfirmation;
