import { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import auth0Client from '../../clients/auth0Client';
import mapStateToProps from './selectors';

interface LoginPageProps extends RouteComponentProps {
  backTo?: string;
}

interface LoginPageState {}

class LoginPage extends Component<LoginPageProps, LoginPageState> {
  async componentDidMount() {
    const { backTo } = this.props;
    await auth0Client.loginWithRedirect({ backTo });
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps)(LoginPage);
