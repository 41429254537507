import Heading1 from 'raydiant-elements/typography/Heading1';
import { UAParser } from 'ua-parser-js';
import * as A from '../clients/mira/types/Application';
import config from '../config';

const userAgent = new UAParser(navigator.userAgent);
const operatingSystem = userAgent.getOS().name;

export const isAppPreviewSupported = (appName: string) => {
  const blacklist =
    operatingSystem && config.appPreviewBlacklist[operatingSystem];
  if (!blacklist) return true;
  return !blacklist.includes(appName);
};

export const getAppPreviewNotSupportedError = (
  appVersion?: A.ApplicationVersion,
) => {
  if (appVersion && !isAppPreviewSupported(appVersion.name)) {
    return (
      <Heading1>Sorry, preview unavailable for this app on mobile.</Heading1>
    );
  }
  return undefined;
};
