import useProtectedQuery from './useProtectedQuery';
import raydiant from '../clients/miraClient';
import { ApiClientError, Application } from '@raydiant/api-client-js';
import { keys } from '../queryClient';

export default function useMarketPlaceApplications() {
  return useProtectedQuery<Application[], ApiClientError>(
    keys.marketPlaceApplications(),
    () => raydiant.getMarketPlaceApplications(),
  );
}
