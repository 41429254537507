import { FC, forwardRef } from 'react';
import cn from 'classnames';
import { Presentation } from '@raydiant/api-client-js';
import useStyles from './PresentationCard.styles';

export interface PresentationCardThumbnailProps {
  presentation: Presentation;
  isDark?: boolean;
}

export const PresentationCardThumbnail: FC<PresentationCardThumbnailProps> = (
  { presentation, isDark },
  ref,
) => {
  const classes = useStyles();

  const thumbnailImageUrl =
    presentation.thumbnailUrl || presentation.applicationThumbnailUrl;

  return (
    <div
      ref={ref}
      className={cn(
        classes.thumbnailImage,
        isDark && classes.thumbnailImageDark,
      )}
      style={{ backgroundImage: `url(${thumbnailImageUrl})` }}
    />
  );
};

// @ts-ignore
export default forwardRef(PresentationCardThumbnail);
