import { FC, useEffect, useState, useCallback, useRef } from 'react';
import moment from 'moment';
import Switch from 'raydiant-elements/core/Switch';
import SelectField from 'raydiant-elements/core/SelectField';
import InputLabel from 'raydiant-elements/core/InputLabel';
import Link from 'raydiant-elements/core/Link';
import ToggleButtonGroup from 'raydiant-elements/core/ToggleButtonGroup';
import Column from 'raydiant-elements/layout/Column';
import { Profile } from '@raydiant/api-client-js';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserProfile } from '../../selectors/user';
import * as userActions from '../../actions/user';
import SectionIndent from '../../components/SectionIndent';
interface AccountSettingsProps {}

const remoteAssistanceOptions = [
  { value: '', label: 'Select duration' },
  { value: '1day', label: '1 day' },
  { value: '1week', label: '1 week' },
  { value: '1month', label: '1 month' },
  { value: '1year', label: '1 year' },
  { value: 'indefinitely', label: 'Indefinitely' },
];

const isRemoteAssistanceEnabled = (profile: Profile) => {
  return (
    profile.remoteAssistanceGrantLevel !== null &&
    (profile.remoteAssistanceExpiresAt === null ||
      moment().isBefore(profile.remoteAssistanceExpiresAt))
  );
};

const AccountSettings: FC<AccountSettingsProps> = () => {
  const dispatch = useDispatch();

  // Selectors

  const profile = useSelector(selectUserProfile);

  // State

  const [
    enableDeviceOfflineNotifications,
    setEnableDeviceOfflineNotifications,
  ] = useState(false);

  const [enableMFA, setEnableMFS] = useState(false);

  const [enableRemoteAssistance, setEnableRemoteAssistance] = useState(false);

  const [remoteAssistanceGrantLevel, setRemoteAssistanceGrantLevel] = useState<
    string | null
  >(null);

  const [remoteAssistanceDuration, setRemoteAssistanceDuration] =
    useState<string>('');

  // Callbacks

  const updateProfile = useCallback(
    (updatedProfile: Partial<Profile>) => {
      if (!profile) return;
      dispatch(
        userActions.updateProfile({
          ...profile,
          ...updatedProfile,
        }),
      );
    },
    [dispatch, profile],
  );

  // Side-effects

  // Update state when profile changes.
  useEffect(() => {
    if (!profile) return;
    setEnableMFS(profile.mfaEnabled);
    setEnableDeviceOfflineNotifications(
      profile.enableDeviceOfflineNotifications,
    );

    if (isRemoteAssistanceEnabled(profile)) {
      setEnableRemoteAssistance(true);
      setRemoteAssistanceGrantLevel(profile.remoteAssistanceGrantLevel);
    }
  }, [profile]);

  // Save device notifications when changed.
  const prevEnableDeviceOfflineNotifications = useRef<boolean | null>(null);
  useEffect(() => {
    if (
      prevEnableDeviceOfflineNotifications.current !== null &&
      enableDeviceOfflineNotifications !==
        prevEnableDeviceOfflineNotifications.current
    ) {
      updateProfile({ enableDeviceOfflineNotifications });
    }
    prevEnableDeviceOfflineNotifications.current =
      enableDeviceOfflineNotifications;
  }, [updateProfile, enableDeviceOfflineNotifications]);

  // Save mfa enabled when changed.
  const prevEnableMFA = useRef<boolean | null>(null);
  useEffect(() => {
    if (prevEnableMFA.current !== null && enableMFA !== prevEnableMFA.current) {
      updateProfile({ mfaEnabled: enableMFA });
    }
    prevEnableMFA.current = enableMFA;
  }, [updateProfile, enableMFA]);

  // Save remote assistance when grant level or duration changed.
  const prevRemoteAssistanceGrantLevel = useRef<string | null>(null);
  const originalRemoteAssistanceExpiresAt = profile?.remoteAssistanceExpiresAt;
  useEffect(() => {
    if (!remoteAssistanceGrantLevel) return;
    if (!remoteAssistanceDuration) return;

    let remoteAssistanceExpiresAt: string | null = null;
    if (remoteAssistanceDuration === '1day') {
      remoteAssistanceExpiresAt = moment().add(1, 'days').toISOString();
    } else if (remoteAssistanceDuration === '1week') {
      remoteAssistanceExpiresAt = moment().add(1, 'weeks').toISOString();
    } else if (remoteAssistanceDuration === '1month') {
      remoteAssistanceExpiresAt = moment().add(1, 'months').toISOString();
    } else if (remoteAssistanceDuration === '1year') {
      remoteAssistanceExpiresAt = moment().add(1, 'years').toISOString();
    } else if (originalRemoteAssistanceExpiresAt) {
      remoteAssistanceExpiresAt = originalRemoteAssistanceExpiresAt;
    }

    if (remoteAssistanceGrantLevel !== prevRemoteAssistanceGrantLevel.current) {
      dispatch(
        userActions.setRemoteAssistance({
          grantLevel: remoteAssistanceGrantLevel,
          expiresAt: remoteAssistanceExpiresAt,
        }),
      );
    }
  }, [
    dispatch,
    originalRemoteAssistanceExpiresAt,
    remoteAssistanceGrantLevel,
    remoteAssistanceDuration,
  ]);

  // Reset remote assistance when disabling toggle.
  const prevEnableRemoteAssistance = useRef<boolean | null>(null);
  useEffect(() => {
    if (!enableRemoteAssistance && prevEnableRemoteAssistance.current) {
      setEnableRemoteAssistance(false);
      setRemoteAssistanceGrantLevel('');
      setRemoteAssistanceDuration('');

      dispatch(
        userActions.setRemoteAssistance({
          grantLevel: null,
          expiresAt: null,
        }),
      );
    }

    prevEnableRemoteAssistance.current = enableRemoteAssistance;
  }, [dispatch, enableRemoteAssistance]);

  // Render

  return (
    <Column doubleMargin>
      <Switch
        label="2-step verification"
        checked={enableMFA}
        onChange={setEnableMFS}
        helperText={
          <>
            Enable for enhanced security.{' '}
            <Link
              target="_blank"
              href="https://support.raydiant.com/hc/en-us/articles/360053600633
"
            >
              Tell me more
            </Link>
          </>
        }
      />

      <Switch
        label="Offline device notifications"
        checked={enableDeviceOfflineNotifications}
        onChange={setEnableDeviceOfflineNotifications}
        helperText="An email notification will be sent if this account's devices go offline"
      />

      <div>
        <Switch
          label="Enable remote assistance"
          checked={enableRemoteAssistance}
          onChange={setEnableRemoteAssistance}
          helperText={
            <>
              Grant Customer Support access to your dashboard.{' '}
              <Link
                href={
                  'https://support.raydiant.com/hc/en-us/articles/360060819571'
                }
                target="_blank"
              >
                Learn more
              </Link>
            </>
          }
        />

        {enableRemoteAssistance && (
          <SectionIndent>
            <Column doubleMargin>
              <ToggleButtonGroup
                exclusive
                label="Access permission"
                helperText={
                  <>
                    Set if Support can make changes or just read.{' '}
                    <Link href="'https://support.raydiant.com/hc/en-us/articles/360060819571'">
                      Learn more
                    </Link>
                  </>
                }
                value={remoteAssistanceGrantLevel ?? ''}
                onChange={(value) =>
                  setRemoteAssistanceGrantLevel(
                    Array.isArray(value) ? value[0] : value,
                  )
                }
              >
                <ToggleButtonGroup.Button value="full">
                  Full Access
                </ToggleButtonGroup.Button>
                <ToggleButtonGroup.Button value="read">
                  Read Only
                </ToggleButtonGroup.Button>
              </ToggleButtonGroup>

              {profile && isRemoteAssistanceEnabled(profile) && (
                <div>
                  <InputLabel>Access Duration</InputLabel>
                  <div>
                    Remote assistance enabled{' '}
                    {profile.remoteAssistanceExpiresAt === null
                      ? 'indefinitely.'
                      : `for ${moment(
                          profile.remoteAssistanceExpiresAt,
                        ).fromNow(true)}.`}
                  </div>
                </div>
              )}

              {profile && !isRemoteAssistanceEnabled(profile) && (
                <SelectField
                  label="Access Duration"
                  value={remoteAssistanceDuration}
                  onChange={setRemoteAssistanceDuration}
                  helperText="The time in which Support can access your dashboard"
                >
                  {remoteAssistanceOptions.map((opt, index) => (
                    <option key={index} value={opt.value}>
                      {opt.label}
                    </option>
                  ))}
                </SelectField>
              )}
            </Column>
          </SectionIndent>
        )}
      </div>
    </Column>
  );
};

export default AccountSettings;
