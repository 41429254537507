import {
  createAction,
  createAsyncAction,
  createStandardAction,
} from 'typesafe-actions';
import { Theme, ResourceACL } from '@raydiant/api-client-js';

export interface ThemeFile {
  file: File;
  localUrl: string;
}

export const getThemes = createAction('@THEMES/GET');

export const getThemesAsync = createAsyncAction(
  '@THEMES/GET_REQUEST',
  '@THEMES/GET_SUCCESS',
  '@THEMES/GET_FAILURE',
)<void, Theme[], Error>();

export const updateTheme = createStandardAction('@THEMES/UPDATE')<
  Theme,
  {
    onUpdate?: (theme: Theme) => void;
    pendingBackgroundImageUpload?: ThemeFile;
  }
>();

export const updateThemeAsync = createAsyncAction(
  '@THEMES/UPDATE_REQUEST',
  '@THEMES/UPDATE_SUCCESS',
  '@THEMES/UPDATE_FAILURE',
)<Theme, Theme, Error>();

export const createTheme = createStandardAction('@THEMES/CREATE')<
  Theme,
  {
    onCreate?: (theme: Theme) => void;
    pendingBackgroundImageUpload?: ThemeFile;
  }
>();

export const createAllThemes = createStandardAction('@THEMES/CREATE_ALL')<
  Theme[],
  { onCreate?: (theme: Theme[]) => void }
>();

export const createThemeAsync = createAsyncAction(
  '@THEMES/CREATE_REQUEST',
  '@THEMES/CREATE_SUCCESS',
  '@THEMES/CREATE_FAILURE',
)<Theme, Theme, Error>();

export const shareTheme = createStandardAction('@THEMES/SHARE')<
  {
    themeId: string;
    profileId: string;
  },
  {
    onSuccess?: (resourceACL: ResourceACL) => void;
    onError?: (error: any) => void;
  }
>();

export const addThemeResourceACL = createStandardAction(
  '@THEMES/CREATE_RESOURCE_ACL',
)<{
  themeId: string;
  resourceACL: ResourceACL;
}>();

export const removeThemeResourceACL = createStandardAction(
  '@THEMES/REMOVE_RESOURCE_ACL',
)<{
  themeId: string;
  aclId: string;
}>();

export const deleteAllThemes = createStandardAction('@THEMES/DELETE_ALL')<
  string[],
  { onSuccess?: () => void }
>();

export const deleteThemeAsync = createAsyncAction(
  '@THEMES/DELETE_REQUEST',
  '@THEMES/DELETE_SUCCESS',
  '@THEMES/DELETE_FAILURE',
)<string, string, Error>();
