import { call, put, fork, take, all } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import * as appVersionActions from '../actions/applicationVersions';
import * as A from '../clients/mira/types/Application';
import miraClient from '../clients/miraClient';
import logger from '../logger';

type FetchApplicationVersionsAction = ReturnType<
  typeof appVersionActions.fetchApplicationVersions
>;

export const fetchApplications = function* (applicationId: string) {
  try {
    yield put(appVersionActions.fetchApplicationVersionsAsync.request());
    const data: A.ApplicationVersion[] = yield call(() =>
      miraClient.getApplicationVersions(applicationId),
    );
    yield put(appVersionActions.fetchApplicationVersionsAsync.success(data));
    return data;
  } catch (error: any) {
    logger.error(error);
    yield put(appVersionActions.fetchApplicationVersionsAsync.failure(error));
  }
};

const watchFetchApplicationVersionss = function* () {
  while (true) {
    const action: FetchApplicationVersionsAction = yield take(
      getType(appVersionActions.fetchApplicationVersions),
    );
    yield fork(fetchApplications, action.payload);
  }
};

export default all([fork(watchFetchApplicationVersionss)]);
