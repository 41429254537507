import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { PopoverOrigin } from '@material-ui/core/Popover';
import Row from 'raydiant-elements/layout/Row';
import Hidden from 'raydiant-elements/layout/Hidden';
import Button from 'raydiant-elements/core/Button';
import PaperModalClose from 'raydiant-elements/core/PaperModal/PaperModalClose';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme } from 'raydiant-elements/theme';
import { AffectedDevice } from '@raydiant/api-client-js';
import Popover from '../Popover';

export interface DeleteResourcesConfirmationProps {
  anchorEl?: HTMLElement | null;
  open: boolean;
  resourceName?: string;
  affectedDevices: AffectedDevice[];
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  onClose: () => void;
  onDelete: () => void;
}

const DeleteResourcesConfirmation = ({
  anchorEl,
  open,
  resourceName,
  affectedDevices,
  anchorOrigin,
  transformOrigin,
  onClose,
  onDelete,
}: DeleteResourcesConfirmationProps) => {
  const classes = useStyles();

  // Render

  let affectedDevicesMessage = '';
  if (affectedDevices.length > 0) {
    if (resourceName) {
      if (affectedDevices.length > 1) {
        affectedDevicesMessage = `${resourceName} is used on ${affectedDevices.length} screens`;
      } else if (affectedDevices.length === 1) {
        affectedDevicesMessage = `${resourceName} is used on 1 screen`;
      }
    } else {
      if (affectedDevices.length > 1) {
        affectedDevicesMessage = `This content is used on ${affectedDevices.length} screens`;
      } else if (affectedDevices.length === 1) {
        affectedDevicesMessage = `This content is used on 1 screen`;
      }
    }
  }

  return (
    <Popover
      variant="alert"
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      <div>
        <div className={classes.header}>
          <PaperModalClose className={classes.close} onClick={onClose} />
        </div>

        <div className={classes.message}>
          <>
            {affectedDevicesMessage && <div>- {affectedDevicesMessage}</div>}
            <div>- Deleting is permanent and cannot be undone</div>
          </>
        </div>
        <Row>
          <Button label="Cancel" onClick={onClose} fullWidth />
          <Hidden smDown>
            <Button
              fullWidth
              color="destructive"
              icon={<DeleteForeverIcon />}
              label="Permanently Delete"
              onClick={onDelete}
            />
          </Hidden>
          <Hidden mdUp>
            <Button
              fullWidth
              color="destructive"
              icon={<DeleteForeverIcon />}
              label="Delete"
              onClick={onDelete}
            />
          </Hidden>
        </Row>
      </div>
    </Popover>
  );
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    header: {
      display: 'flex',
      alignItems: 'center',
      height: 32,
      borderBottom: `1px solid ${theme.divider.primary}`,
    },

    close: {
      position: 'absolute',
      top: 0,
      right: 0,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },

    message: {
      margin: 0,
      padding: theme.spacing(4, 2),
      lineHeight: 1.75,
      fontSize: theme.fontSizes.md,

      [theme.breakpoints.down('xs')]: {
        fontSize: theme.fontSizes.sm,
      },
    },
  });
});

export default DeleteResourcesConfirmation;
