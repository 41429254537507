import * as React from 'react';
import ListSubheader from '@material-ui/core/ListSubheader';
import Heading from 'raydiant-elements/core/Heading';
import { makeStyles, createStyles } from 'raydiant-elements/styles';
import { Theme as RETheme } from 'raydiant-elements/theme';

interface OptionHeaderProps {
  label?: React.ReactNode;
}

const OptionHeader = ({ label }: OptionHeaderProps) => {
  const classes = useStyles();

  return (
    <ListSubheader classes={{ root: classes.root, sticky: classes.sticky }}>
      <Heading size={5} overline gutterBottom gutterTop>
        {label}
      </Heading>
    </ListSubheader>
  );
};

const useStyles = makeStyles((theme: RETheme) =>
  createStyles({
    root: {
      '&$sticky': {
        backgroundColor: theme.palette.background.paper,
      },
    },
    sticky: {},
  }),
);

export default Object.assign(OptionHeader, { muiName: 'ListSubheader' });
