import { FC, MouseEventHandler } from 'react';
import cn from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import usePlaylistPageContext from './usePlaylistPageContext';

interface PlaylistItemMoreActionsButtonProps {
  selected?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const PlaylistItemMoreActionsButton: FC<PlaylistItemMoreActionsButtonProps> = ({
  selected,
  onClick,
}) => {
  const { classes } = usePlaylistPageContext();

  return (
    <IconButton
      size="small"
      className={cn(
        classes.playlistItemButton,
        selected && classes.playlistItemButtonSelected,
      )}
      onClick={onClick}
    >
      <MoreHorizIcon color="inherit" />
    </IconButton>
  );
};

export default PlaylistItemMoreActionsButton;
