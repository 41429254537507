import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Theme, ResourceACL } from '@raydiant/api-client-js';
import PaperModal from 'raydiant-elements/core/PaperModal';
import Heading from 'raydiant-elements/core/Heading';
import Text from 'raydiant-elements/core/Text';
import Scrollable from 'raydiant-elements/layout/Scrollable';
import Column from 'raydiant-elements/layout/Column';
import useStyles from './PresentationPage.styles';
import ShareResource from '../../components/ShareResource';
import * as themeActions from '../../actions/themes';

interface ThemeSharingModalProps {
  theme?: Theme;
  onClose: () => void;
}

const ThemeSharingModal = ({ theme, onClose }: ThemeSharingModalProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // Callbacks

  const shareTheme = useCallback(
    async (profileId: string) => {
      if (!theme) return;

      return new Promise<void>((resolve, reject) => {
        dispatch(
          themeActions.shareTheme(
            {
              themeId: theme.id,
              profileId,
            },
            {
              onSuccess: () => resolve(),
              onError: reject,
            },
          ),
        );
      });
    },
    [dispatch, theme],
  );

  const unshareTheme = useCallback(
    async (selectedACL: ResourceACL) => {
      if (!theme) return;

      return new Promise<void>((resolve) => {
        dispatch(
          themeActions.removeThemeResourceACL({
            themeId: theme.id,
            aclId: selectedACL.id,
          }),
        );

        resolve();
      });
    },
    [dispatch, theme],
  );

  // Render

  const isModalOpen = !!theme;

  return (
    <PaperModal
      color="lightGrey"
      className={classes.modal}
      open={isModalOpen}
      onClose={onClose}
    >
      <PaperModal.Header>
        <Heading size={2} color="muted">
          Theme Sharing
        </Heading>
      </PaperModal.Header>
      <Scrollable>
        <PaperModal.Body>
          <Column doubleMargin>
            {theme && <Text small>Share: {theme.name}</Text>}

            {theme && (
              <ShareResource
                resource={theme.resource}
                resourceType="theme"
                onShare={shareTheme}
                onUnshare={unshareTheme}
              />
            )}
          </Column>
        </PaperModal.Body>
      </Scrollable>
    </PaperModal>
  );
};

export default ThemeSharingModal;
