import { FC } from 'react';
import usePlaylistPageContext from './usePlaylistPageContext';

interface PlaylistItemDropIndicatorProps {
  position: 'top' | 'bottom';
}

const PlaylistItemDropIndicator: FC<PlaylistItemDropIndicatorProps> = ({
  position,
}) => {
  const { classes } = usePlaylistPageContext();

  return (
    <div
      className={classes.playlistItemDropIndicatorOver}
      style={{
        top: position === 'top' ? -2 : undefined,
        bottom: position === 'bottom' ? 0 : undefined,
      }}
    />
  );
};

export default PlaylistItemDropIndicator;
