import { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Presentation, ApplicationVersion } from '@raydiant/api-client-js';
import Callout from 'raydiant-elements/core/Callout';
import ActionBar from 'raydiant-elements/core/ActionBar';
import Link from 'raydiant-elements/core/Link';
import Button from 'raydiant-elements/core/Button';
import Popover from 'raydiant-elements/core/Popover';
import Spacer from 'raydiant-elements/layout/Spacer';
import AffectedScreensPopover from 'raydiant-elements/devices/AffectedScreensPopover';
import * as paths from '../../routes/paths';
import * as presentationActions from '../../actions/presentations';
import * as addContentPageActions from '../AddContentPage/actions';
import { selectPresentationsById } from '../../selectors/v2/presentations';
import { hasPresentationChanged } from '../../utilities';
import {
  PresentationUpload,
  PresentationError,
  PresentationFile,
} from '../../types';
import * as actions from './actions';
import { selectLoadingStatus, selectAffectedDevices } from './selectors';
import { useCallback } from 'react';
import getPageUrl from './getPageUrl';
import useQueryParams from './useQueryParams';
import getUnsavedPresentationId from './getUnsavedPresentationId';
import * as deviceActions from '../../actions/devices';
interface PresentationFormActionsProps {
  presentation?: Presentation;
  appVersion?: ApplicationVersion;
  presentationUploads: PresentationUpload[];
  presentationErrors: PresentationError[];
  presentationFiles: PresentationFile[];
}

const PresentationFormActions = ({
  presentation,
  appVersion,
  presentationUploads,
  presentationErrors,
  presentationFiles,
}: PresentationFormActionsProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = useQueryParams();

  // Selectors

  const loadingStatus = useSelector(selectLoadingStatus);
  const affectedDevices = useSelector(selectAffectedDevices);
  const presentationsById = useSelector(selectPresentationsById);

  // State

  const [showAffectedDevices, setShowAffectedDevices] = useState(false);

  // Refs

  // Memoizers

  const originalPresentation =
    presentation && presentationsById[presentation.id];

  const isFormDirty = useMemo(() => {
    if (!presentation) return false;
    if (!appVersion) return false;
    // The form is dirty if we are creating a new presentation (no original presentation).
    if (!originalPresentation) return true;

    return hasPresentationChanged(
      originalPresentation,
      presentation,
      appVersion,
      presentationUploads,
    );
  }, [originalPresentation, presentation, appVersion, presentationUploads]);

  // Callbacks

  const savePresentation = useCallback(
    (onSave?: (savedPresentation: Presentation) => void) => {
      if (!presentation) return;

      if (presentationErrors.length > 0) {
        history.replace(
          getPageUrl({
            presentation,
            queryParams: { ...queryParams, didSave: true },
          }),
        );
      } else {
        dispatch(
          presentationActions.savePresentation(presentation, {
            folderId: queryParams.folderId,
            fileUploads: presentationFiles,
            onSave: (savedPresentation) => {
              history.replace(
                paths.editPresentation(savedPresentation.id, {
                  ...queryParams,
                  didSave: true,
                }),
              );

              // Clear the unsaved presentation state after navigating to edit to prevent
              // a flash of empty state while we're still on /new.
              dispatch(
                actions.clearUnsavedPresentation(
                  getUnsavedPresentationId(presentation),
                ),
              );

              if (onSave) {
                onSave(savedPresentation);
              }
            },
          }),
        );
        dispatch(deviceActions.fetchDevices());
      }
    },
    [
      presentation,
      dispatch,
      history,
      presentationFiles,
      queryParams,
      presentationErrors,
    ],
  );

  const doneEditingPresentation = useCallback(() => {
    if (!presentation) return;

    const redirectAndSelect = (presentationId: string) => {
      if (queryParams.selectionId) {
        dispatch(
          addContentPageActions.selectItem({
            selectionId: queryParams.selectionId,
            item: { id: presentationId, type: 'presentation' },
          }),
        );
      }
      history.push(queryParams.saveTo);
    };

    if (isFormDirty) {
      savePresentation((savedPresentation) =>
        redirectAndSelect(savedPresentation.id),
      );
    } else {
      redirectAndSelect(presentation.id);
    }
  }, [
    presentation,
    queryParams,
    savePresentation,
    isFormDirty,
    history,
    dispatch,
  ]);

  // Render

  const warnings = [];

  if (
    originalPresentation &&
    appVersion &&
    originalPresentation.appVersionId !== appVersion.id
  ) {
    warnings.push(
      'Saving will update content to the newer version of the app, and may cause visual changes.',
    );
  }

  if (affectedDevices.length > 0 && isFormDirty) {
    const count = affectedDevices.length;
    warnings.push(
      <span>
        Saving these changes will affect&nbsp;
        <Link onClick={() => setShowAffectedDevices(true)}>
          {count > 1 ? `${count} screens` : '1 screen'}
        </Link>
      </span>,
    );
  }

  const formErrors = queryParams.didSave ? presentationErrors : [];

  const shouldDisableSave =
    loadingStatus !== 'success' || formErrors.length > 0 || !isFormDirty;

  const shouldDisableDone = shouldDisableSave && !queryParams.didSave;

  return (
    <>
      {warnings.map((warning, i) => (
        <Callout key={i}>{warning}</Callout>
      ))}

      <ActionBar condensed color="light">
        <Spacer />
        <Button
          label="Done"
          color="primary"
          disabled={shouldDisableDone}
          onClick={doneEditingPresentation}
        />
        <Popover.Anchor>
          <Button
            label="Save"
            color="progress"
            disabled={shouldDisableSave}
            onClick={() => savePresentation()}
          />
          <AffectedScreensPopover
            open={showAffectedDevices}
            devices={affectedDevices}
            onClose={() => setShowAffectedDevices(false)}
          />
        </Popover.Anchor>
      </ActionBar>
    </>
  );
};

export default PresentationFormActions;
