import { FC, useState, useEffect, useMemo } from 'react';
import { Presentation } from '@raydiant/api-client-js';
import { areTagsEqual, hasValidTags } from '../../utilities/tagUtils';
import TagManager from '../../components/TagManager';
import usePlaylistPageContext from './usePlaylistPageContext';
import PlaylistModalBody from './PlaylistModalBody';
import PlaylistModalFooter from './PlaylistModalFooter';

interface PresentationModalTagsProps {
  presentation: Presentation;
  onClose: () => void;
}

const PresentationModalTags: FC<PresentationModalTagsProps> = ({
  presentation,
  onClose,
}) => {
  const { updatePresentation } = usePlaylistPageContext();

  // State

  const [tags, setTags] = useState(presentation.resource.r.tags);

  // Memoizers

  const isDirty = useMemo(() => {
    return !areTagsEqual(tags, presentation.resource.r.tags);
  }, [tags, presentation]);

  // Callbacks

  const handleDone = () => {
    const params: DeepPartial<Presentation> = {
      resource: { r: { tags } },
    };

    updatePresentation(presentation.id, params);

    onClose();
  };

  // Side-effects

  // Update state when presentation changes.
  useEffect(() => {
    setTags(presentation.resource.r.tags);
  }, [presentation]);

  // Render

  const enableDoneButton = isDirty && hasValidTags(tags);

  return (
    <>
      <PlaylistModalBody>
        <TagManager tags={tags} onChange={setTags} />
      </PlaylistModalBody>

      <PlaylistModalFooter
        enableDone={enableDoneButton}
        onCancel={onClose}
        onDone={handleDone}
      />
    </>
  );
};

export default PresentationModalTags;
