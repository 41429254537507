import * as T from '../clients/mira/types/Theme';
import createDefaultResource from './createDefaultResource';

export const DEFAULT_THEME_NAME = 'Custom';

export default function createDefaultTheme(
  opts: DeepPartial<T.Theme> = {},
): T.Theme {
  return {
    id: '',
    name: DEFAULT_THEME_NAME,
    headingFont: 'https://fonts.raydiant.com/Roboto-Black.woff',
    headingFontNew: null,
    heading2FontNew: null,
    headingTextColor: '#F1F1F1',
    heading2Font: 'https://fonts.raydiant.com/Roboto-Medium.woff',
    heading2TextColor: '#EAEAEA',
    bodyFont: 'https://fonts.raydiant.com/PlayfairDisplay-Regular.woff',
    bodyFontNew: null,
    bodyTextColor: '#EAEAEA',
    backgroundColor: '#515356',
    borderColor: '#F1F1F1',
    backgroundScale: 'fill',
    backgroundImage: null,
    backgroundImagePortrait: null,
    backgroundImageFileUpload: null,
    presentationId: null,
    ...opts,
    resource: createDefaultResource(opts.resource),
  };
}
