import { batchActions } from 'redux-batched-actions';
import { all, call, fork, put, take } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { Application, Theme } from '@raydiant/api-client-js';
import * as appActions from '../../actions/applications';
import * as themeActions from '../../actions/themes';
import miraClient from '../../clients/miraClient';
import logger from '../../logger';
import * as actions from './actions';

const watchLoadApplicationsPage = function* () {
  while (true) {
    yield take(getType(actions.loadApplicationsPage));

    try {
      yield put(actions.loadApplicationsPageAsync.request());

      const [applications, premiumApplications, themes]: [
        Application[],
        Application[],
        Theme[],
      ] = yield all([
        call(() => miraClient.getApplications()),
        call(() => miraClient.getMarketPlaceApplications()),
        call(() => miraClient.getThemes()),
      ]);

      yield put(
        batchActions([
          actions.loadApplicationsPageAsync.success(),
          appActions.fetchApplicationsAsync.success(applications),
          appActions.fetchPremiumApplicationsAsync.success(premiumApplications),
          themeActions.getThemesAsync.success(themes),
        ]),
      );
    } catch (error: any) {
      logger.error(error);
      yield put(actions.loadApplicationsPageAsync.failure(error));
    }
  }
};

export default all([fork(watchLoadApplicationsPage)]);
